import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/login";
import Register from "./pages/register";
import Home from "./pages/Home";
import RouteProtected from "./components/RouteProtected";
import AdminHome from "./pages/admin/home";
import Spinner from "./components/spinner";
import { useSelector } from "react-redux";
import EspecialistasHome from "./pages/especialistas";
import EditarEspecialista from "./pages/especialistas/editar"
import AgregarEspecialista from "./pages/especialistas/agregar";
import Citas from "./pages/citas";
import './styles/styles.css'; // Importar el archivo de estilos
// import { useState } from "react";

// const [collapsed, setCollapsed] = useState(false);

function App() {
  const {loading} = useSelector(state => state.loader)

  return (
    <div>
      {loading && <Spinner/>}
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />}></Route>
            {/* <Route path="/register" element={<Register />}></Route> */}
            <Route path="/" element={<Home />} />
            <Route
              path="/admin"
              element={
                <RouteProtected>
                  <AdminHome />
                </RouteProtected>
              }
            />
            <Route 
              path="/admin/citas" element={
                <RouteProtected>
                  <Citas/>
                </RouteProtected>              
            }/>
            <Route
              path="/admin/especialistas" element={
                <RouteProtected>
                  <EspecialistasHome/>
                </RouteProtected>
              }
            />
            <Route
              path="/admin/especialistas/editar" element={
                <RouteProtected>
                  <EditarEspecialista/>
                </RouteProtected>
              }
            />
            <Route 
              path="/admin/especialistas/agregar" element={
                <RouteProtected>
                  <AgregarEspecialista/>
                </RouteProtected>              
            }/>
          </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;

// esto es un comentario
