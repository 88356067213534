import firestoreDB from "../firebaseConfig";
import { collection, addDoc, getDocs, query, where } from "firebase/firestore";
import CryptoJS from "crypto-js";

export const CreateUser = async (payload) => {
  try {
    // check if the user already exists by email
    const qry = query(
      collection(firestoreDB, "users"),
      where("correo", "==", payload.correo)
    );
    const qrySnapshot = await getDocs(qry);

    if (qrySnapshot.size > 0) {
      throw new Error("El Usuario Ya Existe");
    }

    //TODO - checar lo del passphrase para encriptar, y ver como encripta usuario y name
    // encriptamos el password
    const passHashed = CryptoJS.AES.encrypt(
      payload.password,
      "ensain-citas"
    ).toString();
    payload.password = passHashed;

    // registramos el usuario
    const docRef = collection(firestoreDB, "users");
    await addDoc(docRef, payload);

    return {
      success: true,
      message: "Usuario Creado Exitosamente",
    };
  } catch (error) {
    return error;
  }
};


//LOGIN

export const LoginUser = async (paylod) => {
    try {
        const qry = query(
            collection(firestoreDB, "users"),
            where("correo", "==", paylod.correo)
        );
        const userSnapshot = await getDocs(qry);
        if(userSnapshot.size === 0){
            throw new Error("Usuario No Existe");
        }

        const userEncryptedData = userSnapshot.docs[0].data();
        userEncryptedData.id = userSnapshot.docs[0].id
        //TODO - lo mismo del passphrase
        const userPasswordBytes = CryptoJS.AES.decrypt(userEncryptedData.password, "ensain-citas");
        const userPassword = userPasswordBytes.toString(CryptoJS.enc.Utf8);

        if(userPassword !== paylod.password){
            throw new Error("Contraseña Incorrecta");
        }
        // aqui se regresa el objeto
        return {
            success: true,
            message: "Ingreso Exitoso",
            data: userEncryptedData,
        }
    } catch (error) {
        return error;
    }
}