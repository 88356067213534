import { createSlice } from "@reduxjs/toolkit";

export const loaderSlice = createSlice(
    {
        name: "loader",
        initialState:{
            loading: false
        },
        reducers:{
            showLoader: (state, action)=>{
                state.loading = action.payload;
            },
            // hideLoader(state){
            //     state.loading = false
            // }
        }
    }
)

export const {showLoader} = loaderSlice.actions;