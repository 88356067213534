import {
    Button,
    Card,
    Form,
    Input,
    Radio,
    Select,
    Space,
    TimePicker,
    message,
    Spin,
    Modal,
    Row
} from "antd";
import { Option } from "antd/es/mentions";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
    GetEspecialidades,
    GetEspecialistaData,
    GetEspecialistas,
    GetEspecialistaConsultasData
} from "../api_calls/Especialistas";
import {
    AddCita
} from "../api_calls/agendar";
import { showLoader } from "../redux/loaderSlice";
import { connectFirestoreEmulator } from "firebase/firestore";
import { Link } from "react-router-dom";

const facultadLista = [
"Externo",
"Escuela de Bachilleres",
"Facultad de Enfermería",
"Facultad de Artes",
"Facultad de Psicología",
"Facultad de Derecho",
"Facultad de Ciencias Naturales",
"Facultad de Química",
"Facultad de Filosofía",
"Facultad de Ingeniería",
"Facultad de Lenguas y Letras",
"Facultad de Contaduría y Administración",
"Facultad de Ciencias Políticas y Sociales",
"Facultad de Informática",
"Facultad de Medicina"
]

function esUltimoDiaDelMes(fecha) {
    const fechaSiguiente = new Date(fecha);
    fechaSiguiente.setDate(fechaSiguiente.getDate() + 1);

    //TODO - mejorar esta logica, y eliminar priints
    if (fecha.getMonth() === 0) {
        console.log("Es eneroooo")
        console.log(fecha.getDate() )
        if(fecha.getDate() >= 28){
            console.log("Condicion para febrero")
            return true
        }
    }else{
        return fechaSiguiente.getMonth() !== fecha.getMonth();
    }
  
    
  }

  function ultimoDiaDelMes(año, mes) {
    return new Date(año, mes + 1, 0).getDate();
  }

  

const fechaActual = new Date();
const fechaActual1 = new Date();
const fechaActual2 = new Date();

console.log(fechaActual)
console.log(fechaActual.getMonth() )
console.log(fechaActual.getMonth() + 1)

const boolUltimoDiaDelMes = esUltimoDiaDelMes(fechaActual);
console.log(boolUltimoDiaDelMes)
//TODO - checa si esUltimoDiaDelMes funcina para todos los casos, ya se hhizo para febrero
//FIXME - esUltimoDiaDelMes se puede mejorar para febrero y otros meses
if (boolUltimoDiaDelMes) {
    fechaActual1.setDate(1)
    fechaActual1.setMonth((fechaActual.getMonth() + 1))
    const ultimoDia = ultimoDiaDelMes(fechaActual1.getFullYear(), fechaActual1.getMonth())
    console.log(ultimoDia)
    fechaActual1.setDate(ultimoDia)
    

    fechaActual2.setDate(1)
    fechaActual2.setMonth((fechaActual.getMonth() + 2))
    const ultimoDia2 = ultimoDiaDelMes(fechaActual2.getFullYear(), fechaActual2.getMonth())
    console.log(ultimoDia2)
    fechaActual2.setDate(ultimoDia2)
    
    
}else{
    fechaActual1.setMonth((fechaActual.getMonth() + 1))
    fechaActual2.setMonth((fechaActual.getMonth() + 2))
}
console.log(fechaActual1)
console.log(fechaActual2)

const mesesWindow = [fechaActual,  fechaActual1, fechaActual2]
console.log(mesesWindow)



function AgendarForm() {
    // const dispatch = useDispatch();
    const [citaForm] = Form.useForm()
    const [radio, setRadioValue] = useState(2)

    const [especialidad, setEspecialidadValue] = useState(null)
    const [especialidadesList, setEspecialidadesList] = React.useState([])
    const [loadingEspacialidades, setLoadingEspecialidades] = useState({
        loading: true,
        disabled: true,
    });

    const [especialista, setEspecialistaValue] = useState(null)
    const [diasLaborables, setDiasLaborables] = useState([])
    const [loadingEspecialista, setLoadingEspecialista] = useState(true)

    const [especialistasList, setEspecialistasList] = React.useState([])
    const [loadingEspacialistas, setLoadingEspecialistas] = useState({
        loading: true,
        disabled: true,
    });

    const [dias, setDias] = useState([]);
    const [dia, setDia] = useState(mesesWindow[0].getDate()-1);
    const [mes, setMes] = useState(mesesWindow[0]);
    const [cargoPagina, setCargoPagina] = useState(false)
    const [diasOptions, setDiasOptions] = useState()

    const [disableHoraSelect, setDisableHoraSelect] = useState(false)
    const [horas, setHoras] = useState([]);
    const [horasBloqueadas, setHorasBloqueadas] = useState([])
    const [loading, setLoading] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [isEncuestaVisible, setIsEncuestaVisible] = useState(false)
    const [fechaCita, setFechaCita] = useState(null)

    const getEspecialiadadesData = async () => {
        try {
        //   dispatch(showLoader(true))
            setLoadingEspecialidades({
                ...loadingEspacialidades,
                loading: false,
                disabled: false,
            });
            const response = await GetEspecialidades()
            //   dispatch(showLoader(false)) 
            if (response.success) {
                // message.success(response.message);
                //TODO - agregar logica de solo mostrar espcialidades con estado activo
                const lista = response.data.map((option) => {
                    return (
                        <Option value={option.id}>
                            <span translate="no">{option.especialidad}</span>
                        </Option>
                    )
                });
                //TODO - cambiar este tipo de render de Option a algo mejor, como dentro del mismo select
                setEspecialidadesList(lista)
                setLoadingEspecialidades({
                    ...loadingEspacialidades,
                    loading: false,
                    disabled: false,
                });
            } else {
                throw new Error(response.message)
            }
            } catch (error) {
            //   dispatch(showLoader(false)) 
            message.error(error.message)
            setLoadingEspecialidades({
                ...loadingEspacialidades,
                loading: false,
                disabled: false,
            });
        }
    }

    const getEspecialistasData = async (especialidad1) => {
        try {
            //   dispatch(showLoader(true))
            setLoadingEspecialistas({
                ...loadingEspacialistas,
                loading: true,
                disabled: true,
            });
            const response = await GetEspecialistas();
            //   dispatch(showLoader(false))
            if (response.success) {
                // message.success(response.message);
                const lista = response.data.map((option) => {
                    if (option.especialidad === especialidad1) {
                        return (
                            <Option value={option.id}>
                                <span translate="no">{option.nombre + " " + option.ape_paterno}</span>
                            </Option>
                        );
                    } else {
                        return 
                    }
                });
                setEspecialistaValue(null);
                setEspecialistasList(lista);
                setLoadingEspecialistas({
                    ...loadingEspacialistas,
                    loading: false,
                    disabled: false,
                });
            } else {
                throw new Error(response.message);
            }
        } catch (error) {
            //   dispatch(showLoader(false))
            message.error(error.message);
            setLoadingEspecialistas({
                ...loadingEspacialistas,
                loading: true,
                disabled: true,
            });
        }
    };

    const getEspecialistaData = async (especialistaId) => {
        setLoadingEspecialista(true)
        try {
            const listaHoras = []
            let horaInicio = new Date()
            const horaFinal= new Date()
        //   dispatch(showLoader(true))
          const response = await GetEspecialistaData(especialistaId)
        //   dispatch(showLoader(false)) 
          if (response.success) {
            //console.log("este es el response.data")
            //console.log(response.data)
            //console.log("de set especialista")
            //console.log(response.data.dias_laborables)
            // //console.log(response.data.dias_laborables.includes(1))
            //console.log(response.data.dias_laborables.includes(4))

            const listaDiasLaborables = response.data.dias_laborables.map((valor, index) =>{
                return valor
            })

            setDiasLaborables(listaDiasLaborables)

            //console.log(listaDiasLaborables)
            // //console.log("a ver si lo de arriba")
            // const diasOptions = dias.map((diaFecha, index) => ({
            //     label: diaFecha.getDate().toString() + " " + diaFecha.toLocaleString('es-Mx', { weekday: 'long' }),
            //     value: index,
            //     disabled: !listaDiasLaborables.includes(diaFecha.getDay()) || diaFecha.getDay() === 0 || diaFecha.getDay() === 6
            //   }));

            //   setDiasOptions(diasOptions)
            //   //console.log(diasOptions)

            horaInicio.setHours(response.data.hora_entrada)
            horaInicio.setMinutes(response.data.minuto_entrada)
            horaFinal.setHours(response.data.hora_salida)
            horaFinal.setMinutes(response.data.minuto_salida)

            //TODO - verificar la logica, hora de salida es como limite el ultimo paciente o antes de esa hora
            while (horaInicio <= horaFinal){
                listaHoras.push(horaInicio)                
                const proximaHora = new Date(horaInicio)
                proximaHora.setMinutes(proximaHora.getMinutes()+response.data.duracion_cita)
                horaInicio = proximaHora
            }
            setHoras(listaHoras)
            // //console.log(listaHoras)
            setLoadingEspecialista(false)
            return listaHoras

          } else {
            setLoadingEspecialista(false)
            throw new Error(response.message)
          }
        } catch (error) {
        //   dispatch(showLoader(false)) 
        setLoadingEspecialista(false)
          message.error(error.message)
        }
      }

      const getEspecialistaConsultasData = async (especialistaId, diaFecha) => {
       
        try {
        //   dispatch(showLoader(true))
            const response = await GetEspecialistaConsultasData(especialistaId, diaFecha)
        //   dispatch(showLoader(false)) 
          if (response.success) {
            // //console.log(response.data)
            // //console.log(response.data[0].fecha)
            // //console.log(response.data[0].fecha.toDate().toLocaleString())
            return response.data

          } else {
            throw new Error(response.message)
          }
        } catch (error) {
        //   dispatch(showLoader(false)) 
            //console.log(error.message)
            message.error(error.message)
        }
      }

    const onChangeEspecialidad = (value) => {
        // if (radio === 2) {
            //TODO - reusar la lista para no solicitar a firebase
            getEspecialistasData(value);
            citaForm.setFieldValue("especialista", null)
            citaForm.setFieldValue("hora", null)
            setHoras([])
        // }
        setEspecialidadValue(value);
    };

    const onChangeRadio = (e) => {
        //TODO - modificar para que solo 1 vez se haga la carga de firebase
        if (e.target.value === 2) {
            getEspecialistasData(especialidad);
        }
        else {
            setEspecialistaValue(null);
            setEspecialistasList([]);
        }
        setRadioValue(e.target.value);
    };

    const onChangeEspecialista = (value) => {
        //console.log("se cambio especialista")
        //console.log(horas)
        let listaHorasDia
        getEspecialistaData(value).then((listaHoras)=>{
            listaHorasDia = listaHoras
        }).catch((error) =>{
            //console.log('hubo un error en getEspecialistaData')
        })
        setEspecialistaValue(value)
        //console.log('checa aqui', dias[dia])
        //console.log(dias)
        //console.log(dia)

        const currentDate = new Date();
        const timezoneOffsetMinutes = currentDate.getTimezoneOffset();
        const timezoneOffsetHours = -timezoneOffsetMinutes / 60;
        const diferenciaHora = -6-(timezoneOffsetHours) // - 6 - (-5)=-1

        if (dia !== null && mes !== null){
            getEspecialistaConsultasData(value, dias[dia]).then((consultasDia) =>{

                // const fechasconsulta = consultasDia.map((item) => item.fecha.toDate())
                const fechasconsulta = consultasDia.map((item) => {
                    const timestamp = item.fecha;
                    const date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
                    date.setHours(date.getHours() + diferenciaHora); //
                    return date;
                });
                
 
                  console.log(timezoneOffsetHours)
                  console.log(diferenciaHora)
                console.log("AQUI SON LAS CITAS DEL DIA")
                console.log(fechasconsulta)    
                console.log("AQUI SON LAS HORAS DEL DIA")
                console.log(horas)
                

                const horasBloquear = listaHorasDia.map((hora1, index) => {
                    const coincide = fechasconsulta.findIndex((hora2) =>
                        hora1.getHours() == hora2.getHours() && 
                        hora1.getMinutes() === hora2.getMinutes()
                    );
                    return coincide !== -1 ? index : -1;
                  });
                //console.log(horasBloquear.filter((posicion) => posicion !== -1))
                //console.log('antes de ser horas bloqueadas')
                setDisableHoraSelect(true)
                citaForm.resetFields(['dia'])
                citaForm.resetFields(['hora'])
                setDia(null)
                setHorasBloqueadas(horasBloquear.filter((posicion) => posicion !== -1))    
            }).catch((error) =>{
                //console.log('hubo un error on change especialista')
                citaForm.resetFields(['dia'])
                citaForm.resetFields(['hora'])
                setDia(null)
                setDisableHoraSelect(true)
            })
        }
        // setHoras([])
    }

    const onChangeMes = (value) => {
        // const fechaBase = new Date()
        const fechaBase = mesesWindow[value]
        console.log("FECHA BASE")
        console.log(fechaBase)
        console.log("VALUE")
        console.log(value)


        const ultimoDiaMes = new Date(fechaBase.getFullYear(), fechaBase.getMonth() + 1, 0).getDate()
        const diasMes = Array.from({length: ultimoDiaMes}, (_, index) => index+1)
        console.log("ULTIMO DIA MES Y DIAS MES")
        console.log(ultimoDiaMes)
        console.log(diasMes)

        const diasFecha= diasMes.map(numeroDia => {
            const fecha = new Date(fechaBase)
            fecha.setDate(numeroDia)
            return fecha
        })



        setDias(diasFecha)
        // setDia(diasMes[0])
        // citaForm.resetFields()
        citaForm.resetFields(['dia'])
        citaForm.resetFields(['hora'])
        setDia(null)
        setDisableHoraSelect(true)
        // //console.log('change mes dia', diasMes[0])
        // //console.log('change mes value', value)
        // //console.log(diasFecha)
        // //console.log(diasMes)
    }


    const onChangeDia = (value) => {
        setDia(value)
        const currentDate = new Date();
        const timezoneOffsetMinutes = currentDate.getTimezoneOffset();
        const timezoneOffsetHours = -timezoneOffsetMinutes / 60;
        const diferenciaHora = -6-(timezoneOffsetHours) // - 6 - (-5)=-1

        if (especialista !== null){
            getEspecialistaConsultasData(especialista, dias[value]).then((consultasDia) =>{

                const fechasconsulta = consultasDia.map((item) => {
                    const timestamp = item.fecha;
                    const date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
                    date.setHours(date.getHours() + diferenciaHora); //
                    return date;
                });
                
 
                  console.log(timezoneOffsetHours)
                  console.log(diferenciaHora)
                console.log("AQUI SON LAS CITAS DEL DIA")
                console.log(fechasconsulta)    
                console.log("AQUI SON LAS HORAS DEL DIA")
                console.log(horas)
                const horasBloquear = horas.map((hora1, index) => {
                    const coincide = fechasconsulta.findIndex((hora2) =>
                        hora1.getHours() == hora2.getHours() && 
                        hora1.getMinutes() === hora2.getMinutes()
                    );
                    return coincide !== -1 ? index : -1;
                  });
                console.log("AQUI SON LOS INDEX BLOQUEADAS")
                console.log(horasBloquear.filter((posicion) => posicion !== -1))
                setHorasBloqueadas(horasBloquear.filter((posicion) => posicion !== -1))    
                setDisableHoraSelect(false)
            }).catch((error) =>{
                //console.log('hubo un error on change dia')
                setDisableHoraSelect(true)
            })
        }
    }


    useEffect(()=>{
        getEspecialiadadesData();
        setDia(mesesWindow[0].getDate()-1)
        setMes(0)

        const fechaBase = new Date()
        fechaBase.setMonth(mesesWindow[0].getMonth())

        const ultimoDiaMes = new Date(fechaBase.getFullYear(), fechaBase.getMonth() + 1, 0).getDate()
        const diasMes = Array.from({length: ultimoDiaMes}, (_, index) => index+1)

        const diasFecha= diasMes.map(numeroDia => {
            const fecha = new Date(fechaBase)
            fecha.setDate(numeroDia)
            return fecha
        })

        setDias(diasFecha)
        console.log(mesesWindow)

        // setCargoPagina()
      }, [])

    const onFinish = (values) => {
        setIsModalVisible(true)
        const mesCita = mesesWindow[values.mes]
        const diaCita = dias[values.dia]
        const horaCita = horas[values.hora]

        const fechaCita = new Date()
        fechaCita.setFullYear(mesCita.getFullYear())
        fechaCita.setMonth(mesCita.getMonth())
        fechaCita.setDate(diaCita.getDate())
        fechaCita.setHours(horaCita.getHours())
        fechaCita.setMinutes(horaCita.getMinutes())
        setFechaCita(fechaCita)
    }

    const handleConfirm = async () => {
        // let formattedFechaCita
        setIsEncuestaVisible(true)
        try {
            const values = await citaForm.validateFields()
            setLoading(true); // Mostrar el loading spinner mientras se guarda el documento

            //console.log('Success:', values);
            const mesCita = mesesWindow[values.mes]
            const diaCita = dias[values.dia]
            const horaCita = horas[values.hora]

            // const fechaCita = new Date()

            const timezoneOffsetMinutes = fechaCita.getTimezoneOffset();
            const timezoneOffsetHours = -timezoneOffsetMinutes / 60;
            const diferenciaHora = -6-(timezoneOffsetHours) // - 6 - (-5)=-1

            fechaCita.setFullYear(mesCita.getFullYear())
            fechaCita.setMonth(mesCita.getMonth())
            fechaCita.setDate(diaCita.getDate())
            fechaCita.setHours(horaCita.getHours()-diferenciaHora)
            
            // fechaCita.setHours(horaCita.getHours())

            fechaCita.setMinutes(horaCita.getMinutes())
            console.log(horaCita.getHours())
            console.log(diferenciaHora)
            console.log(horaCita.getHours()-diferenciaHora)
            console.log("ESTA FUE LA FECHA")
            console.log(fechaCita)
        


            // formattedFechaCita = fechaCita ? fechaCita.toLocaleString() : null;

            await AddCita(values, fechaCita).then((value) => {
                setLoading(false)
                setIsModalVisible(false)
                setEspecialistasList([])
                setHoras([])
                setDias([])
                citaForm.resetFields()
                window.location.reload(); 

            }).catch((error) => {
                //console.log(error.message)
                setLoading(false)
                setIsModalVisible(false)
                message.error(error.message)
            })
           
            
            // setIsEncuestaVisible(false)
            
        } catch{
            message.error("No se pudo agendar")
            // setIsEncuestaVisible(false)
        } 
        
    }

    const handleCancel = () => {
        setIsModalVisible(false); // Cerrar el Modal de confirmación si el usuario cancela
        setIsEncuestaVisible(false)
        
      }

      const handleCancel2 = () => {
        setIsModalVisible(false); // Cerrar el Modal de confirmación si el usuario cancela
        setIsEncuestaVisible(false)
      window.location.reload(); 
    }


    return (
        <div>
        <Card
            title="Agenda Tu Cita"
            bordered={true}
            style={{
                // width: 600,
                textAlign: "center",
            }}
        >
        {loading && (
            <div className="loading-overlay">
            <Spin size="large" />
            </div>
        )}
        <Modal
            title="Confirmación"
            open={isModalVisible}
            onOk={handleConfirm}
            onCancel={handleCancel}
            // onOk={handleCancel2}
            // onCancel={handleCancel2}
            okText="Confirmar"
            cancelText="Cancelar"
        >
            <p>¿Confirmas tu Cita?</p>
            {/* <p>Cita de: {citaForm.getFieldMeta('especialidad').label}</p>
            <p>Especialista: {citaForm.getFieldMeta('especialista').label}</p> */}
            <p>
                Fecha: {fechaCita
                    ? fechaCita.toLocaleString('es-ES', {
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true // Mostrar en formato de 12 horas con AM/PM
                    })
                    : null}
            </p>
        </Modal>
        
        {/* <Modal
            title="Cita Agendada!"
            open={isEncuestaVisible}
            onOk={handleCancel2}
            onCancel={handleCancel2}
            // okText="Confirmar"
            cancelText="salir"
        >
            <p>Participa en la encuesta de satisfaccion del servicio</p>
            <p>Nos regalas unos segundo para contestar nuestra encuesta?:</p>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSdgDgVOumc2qYtb9Xsrgg4-tHWRFpjf-Oq1UsIzn_NlCiQJbA/viewform" 
                target="_blank">https://docs.google.com/forms/d/e/1FAIpQLSdgDgVOumc2qYtb9Xsrgg4-tHWRFpjf-Oq1UsIzn_NlCiQJbA/viewform
            </a>
        </Modal> */}

            <Form
                {...{
                    // wrapperCol: {
                    //     span: 12,
                    //     offset: 3,
                    // },
                    // labelCol: {
                    //     span: 12,
                    //     offset: 3,
                    //     style: { textAlign: "center" },
                    // } ,
                    // wrapperCol: {sm: {span: 3, offset: 12}},
                    // labelCol: {sm: {span: 3, offset: 12}}
                }}
                layout="Vetical"
                form={citaForm}
                size="medium"
                onFinish={onFinish}
            >
                <Form.Item
                    label="Nombre"
                    name="nombre"
                    rules={[
                        {
                            required: true,
                            message: "ingresa tu(s) apellido(s)",
                        },
                    ]}
                >
                    <Input placeholder="Tu nombre" />
                </Form.Item>
                <Form.Item
                    label="Apellido"
                    name="apellido"
                    rules={[
                        {
                            required: true,
                            message: "ingresa tu(s) apellido(s)",
                        },
                    ]}
                >
                    <Input placeholder="Tus apellidos" />
                </Form.Item>
                <Form.Item
                    // {...tailLayout2}
                    name="especialidad"
                    label={<span translate="no">Tu cita en</span>}
                    rules={[
                        {
                            required: true,
                            message: "Selecciona el tipo de cita",
                        },
                    ]}
                >
                    <Select
                        placeholder="De que sera tu cita?"
                        // allowClear
                        onChange={onChangeEspecialidad}
                        value={especialidad}
                    >   
                        {/* // TODO - por ejemplo aqui el warning de Option */}
                        {especialidadesList}
                    </Select>
                </Form.Item>

                {/* <Form.Item name="paciente">
                    <Radio.Group onChange={onChangeRadio} 
                        value={radio} 
                        // defaultValue={1}
                    >
                        <Radio value={1}>Nuevo Paciente</Radio>
                        <Radio value={2}>Cita Seguimiento</Radio>
                    </Radio.Group>
                </Form.Item> */}

                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues.paciente !== currentValues.paciente ||
                        prevValues.especialidad !== currentValues.especialidad
                    }
                >
                    {({ getFieldValue }) =>
                        // getFieldValue("paciente") === 2 ? (
                        getFieldValue("paciente") !== 0 ? (
                            <Form.Item name="especialista"
                                initialValue={null}
                                preserve={false}
                                label={<span translate="no">Especialista</span>}
                                rules={[
                                    {
                                        required: true,
                                        message: "Selecciona el especialista",
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Con quien es tu cita?"
                                    // loading={loadingEspacialistas.loading}
                                    disabled={loadingEspacialistas.disabled}
                                    value={especialista}
                                    // defaultValue={null}
                                    onChange={onChangeEspecialista}
                                    // onDeselect={setEspecialistaValue(null)}
                                >
                                    {especialistasList}
                                </Select>
                            </Form.Item>
                        ) : null
                    }
                </Form.Item>

                {/* <div style={{ display: 'flex', flexDirection: 'column' }}> */}
                <Row> 
                    <span translate="no">Dia y Hora de la Cita:</span>
                      
                </Row>
                           
                    <Space wrap direction="horizontal">
                        {/* //TODO - Agregar un mes mas (4) cuando sean los ultimos de mes */}
                        
                        <Form.Item name="mes" initialValue={0}
                        // label="Dia y hora de la cita"
                        rules={[
                            {
                                required: true,
                                message: "Selecciona el mes",
                            },
                        ]}
                        
                        >
                        <Select
                            // defaultValue={0}
                            style={{
                                width: 90,
                            }}
                            onChange={onChangeMes}
                            options={mesesWindow.map((mesDate, index) => ({
                                label: <span translate="no">{mesDate.toLocaleString('es-MX', {month: 'long'})}</span>,
                                value: index,
                            }))}
                        />
                        </Form.Item>

                        {/* especialistaData;;;===; */}
                        
                        <Form.Item name="dia" initialValue={null}
                        rules={[
                            {
                                required: true,
                                message: "Selecciona el dia",
                            },
                        ]}
                        >
                        {/* {diasOptions ? ( */}
                            
                            <Select
                            // defaultValue={mesesWindow[0].getDate()}
                            disabled={loadingEspacialistas.disabled}
                            placeholder="dia"
                            style={{
                                width: 90,
                            }}
                            // value={dia}
                            onChange={onChangeDia}
                            options= 
                            {dias.map((diaFecha, index) => ({
                                label: <span translate="no">{diaFecha.getDate().toString() + " " + diaFecha.toLocaleString('es-Mx', {weekday: 'long'})}</span>,
                                value: index,
                                // disabled: diaFecha.getDay() === 0 || diaFecha.getDay() === 6
                                // disabled: especialistaData.nombre === "yo" ? true :false
                                // disabled: especialistaData.dias_laborales.find( diaFecha.getDay())
                                disabled: !diasLaborables.includes(diaFecha.getDay()) || diaFecha.getDay() === 0 || diaFecha.getDay() === 6 || diaFecha < fechaActual
                            }))}
                        />

                        {/* ): (
                            <p> {especialistaData.dias_laborables} </p>
                        )
                        }
                         */}
                        </Form.Item>
                        <Form.Item name="hora" initialValue={null}
                        rules={[
                            {
                                required: true,
                                message: "Selecciona la hora",
                            },
                        ]}
                        >
                            
                        <Select
                            style={{
                                width: 100,
                            }}
                            placeholder="hora"
                            disabled={loadingEspacialistas.disabled || disableHoraSelect}
                            // onChange={onChangeDia}
                            options=
                            {horas.map((hora, index) => ({
                                //TODO - cambiar el toLocaleString por solo conversion a string
                                
                                label: <span translate="no">{hora.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</span>,
                                // label: <span translate="no">{new Date(hora - (6 * 60 * 60 * 1000)).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</span>,
                                value: index,
                                disabled: horasBloqueadas ? horasBloqueadas.includes(index) : false
                            }))}
                            />
                            
                            </Form.Item>
                        {/* <TimePicker size="small" use12Hours format="h:mm a" /> */}
                    </Space>
               {/* </div> */}

                <Form.Item
                    label= {<span translate="no">Celular</span>}
                    name="celular"
                    rules={[
                        { required: true,
                          pattern: /^[0-9]{10}$/,
                          message: "El teléfono debe tener 10 dígitos numéricos",
                        },
                      ]}
                >
                    <Input placeholder="Tu celular" maxLength={10} />
                </Form.Item>

                <Form.Item
                    // {...tailLayout2}
                    name="facultad"
                    label={<span translate="no">Facultad</span>}
                    rules={[
                        {
                            required: true,
                            message: "Selecciona la opcion",
                        },
                    ]}
                >

                <Select
                style={{
                    // width: 100,
                }}
                placeholder="De donde nos visitas?"
                // disabled={loadingEspacialistas.disabled}
                // onChange={onChangeDia}
                options=
                {facultadLista.map((facultad, index) => ({
                    //TODO - cambiar el toLocaleString por solo conversion a string
                    label: <span translate="no">{facultad}</span>,
                    value: facultad,
                    // disabled: horasBloqueadas ? horasBloqueadas.includes(index) : false
                }))}
                            />
                </Form.Item>

                <Form.Item>
                    <Button className="boton-confirmar" type="primary" htmlType="submit" disabled={loading}>
                        Agendar
                    </Button>
                </Form.Item>
            </Form>
        </Card>
        
        </div>
    );
}

export default AgendarForm;
