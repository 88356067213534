
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MenuLayout from "./MenuLayout";



function RouteProtected({ children }) {
  const navigate = useNavigate();
 
  useEffect(() => {

    const user = JSON.parse(localStorage.getItem("user"));
    //TODO - verificar si el usar tiene rol de admin min 13 video 18
    if (!user) {
      navigate("/login");
    }
  }, [navigate]);

  //tambien aqui agregar si el user es admin 
  return ( <> {
    JSON.parse(localStorage.getItem("user")) ? (
      <MenuLayout>
        {children}
      </MenuLayout>
    ) : (null) }
    </>
  )
}

export default RouteProtected;





    // <div>
    //   <Menu mode="horizontal">
    //     <Menu.Item key="home">
    //       <Link to="/">Home</Link>
    //     </Menu.Item>
    //     <Menu.Item key="about">
    //       <Link to="/perfil">Perfil</Link>
    //     </Menu.Item>
    //     <Menu.Item key="contact">
    //       <Link to="/salir">Salir</Link>
    //     </Menu.Item>
    //   </Menu>
    // </div>

    // <div>
    //     {children}
    // </div>