import { addDo, addDoc, collection, doc, getDoc, getDocs, query } from "firebase/firestore"
import firestoreDB from "../firebaseConfig"
import { getFirestore, Timestamp } from 'firebase/firestore';

export const AddCita = async(paylod, fechaCita)=>{
    // try {
    //     await addDoc(collection(firestoreDB, "especialistas"), paylod)
    //     return {
    //         success: true,
    //         message: "Especialista Creado Exitosamente"
    //     }
    // } catch (error) {
    //     return {
    //         success: false,
    //         message: error.message
    //     }
    // }
    const especialistaId = paylod.especialista

    const collectionPath = `especialistas/${especialistaId}/consultas/${fechaCita.getFullYear().toString()}/${(fechaCita.getMonth() + 1).toString().padStart(2, '0')}/${fechaCita.getDate().toString().padStart(2, '0')}/consultas_dias`
    console.log(collectionPath)
    const datos = {
        estado: 'confirmado',
        fecha: Timestamp.fromDate(fechaCita),
        pacienteCelular: paylod.celular,
        especialista: paylod.especialista,
        tipo: paylod.paciente === 1 ? 'nuevo': 'seguimiento',
        especialidad: paylod.especialidad,
        nombre: paylod.nombre,
        apellido: paylod.apellido,
        facultad: paylod.facultad
    }

    try {
        // Guardar el nuevo documento en Firestore
        await addDoc(collection(firestoreDB, collectionPath), datos)
        console.log('Documento guardado exitosamente');
        return {
            success: true,
            message: "cita agendada"
        }
      } catch (error) {
            return {
                success: false,
                message: error.message
            }
      }


}



export const AdminAddCita = async(paylod, fechaCita)=>{

    const especialistaId = paylod.especialista

    const collectionPath = `especialistas/${especialistaId}/consultas/${fechaCita.getFullYear().toString()}/${(fechaCita.getMonth() + 1).toString().padStart(2, '0')}/${fechaCita.getDate().toString().padStart(2, '0')}/consultas_dias`
    console.log(collectionPath)
    const datos = {
        estado: paylod.estado,
        fecha: Timestamp.fromDate(fechaCita),
        pacienteCelular: paylod.pacienteCelular,
        especialista: paylod.especialista,
        tipo: paylod.tipo,
        especialidad: paylod.especialidad,
        nombre: paylod.nombre,
        apellido: paylod.apellido
    }

    try {
        // Guardar el nuevo documento en Firestore
        const docRef =  await addDoc(collection(firestoreDB, collectionPath), datos)
        console.log('Documento guardado exitosamente');
        return {
            docId: docRef.id,
            success: true,
            message: "cita agendada"
        }
      } catch (error) {
            return {
                success: false,
                message: error.message
            }
      }


}


export const AdminAddCitaCustom = async(paylod, fechaCita)=>{

    const especialistaId = paylod.especialista

    const collectionPath = `especialistas/${especialistaId}/consultas/${fechaCita.getFullYear().toString()}/${(fechaCita.getMonth() + 1).toString().padStart(2, '0')}/${fechaCita.getDate().toString().padStart(2, '0')}/consultas_dias`
    console.log(collectionPath)
    const datos = {
        estado: paylod.estado,
        fecha: Timestamp.fromDate(fechaCita),
        pacienteCelular: paylod.pacienteCelular,
        especialista: paylod.especialista,
        tipo: paylod.tipo,
        especialidad: paylod.especialidad,
        nombre: paylod.nombre,
        apellido: paylod.apellido,
        custom: paylod.custom
    }

    try {
        // Guardar el nuevo documento en Firestore
        const docRef =  await addDoc(collection(firestoreDB, collectionPath), datos)
        console.log('Documento guardado exitosamente');
        return {
            docId: docRef.id,
            success: true,
            message: "cita agendada"
        }
      } catch (error) {
            return {
                success: false,
                message: error.message
            }
      }


}








export const AdminBloquearCita = async(paylod, fechaCita)=>{

    const especialistaId = paylod.especialista

    const collectionPath = `especialistas/${especialistaId}/consultas/${fechaCita.getFullYear().toString()}/${(fechaCita.getMonth() + 1).toString().padStart(2, '0')}/${fechaCita.getDate().toString().padStart(2, '0')}/consultas_dias`
    console.log(collectionPath)
    const datos = {
        estado: paylod.estado,
        fecha: Timestamp.fromDate(fechaCita),
        especialista: paylod.especialista,
        tipo: paylod.tipo,
        especialidad: paylod.especialidad,
        nombre: paylod.nombre,
    }

    try {
        // Guardar el nuevo documento en Firestore
        const docRef =  await addDoc(collection(firestoreDB, collectionPath), datos)
        console.log('Documento guardado exitosamente');
        return {
            docId: docRef.id,
            success: true,
            message: "cita agendada"
        }
      } catch (error) {
            return {
                success: false,
                message: error.message
            }
      }


}