import React, { useEffect, useState } from "react";
import { Button, Form, Input, Col, Row, Select, Space, message, TimePicker } from "antd";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showLoader } from "../../../redux/loaderSlice";
import { AddEspecialista, GetEspecialidades, GetEspecialistaData } from "../../../api_calls/Especialistas";
import FormItem from "antd/es/form/FormItem";
import dayjs from 'dayjs'
// import customParseFormat from 'dayjs/plugin/customParseFormat'
import objectSupport from 'dayjs/plugin/objectSupport'
import {useLocation} from 'react-router-dom';
import { adminGetEspecialistaData, adminUpdateEspecialista } from "../../../api_calls/especialidades";
import moment from 'moment';

// dayjs.extend(customParseFormat)
dayjs.extend(objectSupport)

const { Option } = Select;
const layout3 = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 24,
  },
};

const layout = {
  labelCol: {
    span: 12,
  },
  wrapperCol: {
    span: 24,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 8,
  },
};
const tailLayout2 = {
  wrapperCol: {
    offset: 0,
    span: 10,
  },
};

const diasLista = [
  <Option value={1}>Lunes</Option>,
  <Option value={2}>Martes</Option>,
  <Option value={3}>Miercoles</Option>,
  <Option value={4}>Jueves</Option>,
  <Option value={5}>Viernes</Option>,
//   <Option value={6}>Sabado</Option>,
//   <Option value={0}>Domingo</Option>,
]

const duracionCitaLista = [
  <Option value={15}>15</Option>,
  <Option value={30}>30</Option>,
  <Option value={45}>45</Option>,
  <Option value={60}>60</Option>,
]
//
// 
//
// 
//
// 
//
// 
//
// 
//
// 
//
// 
//
// 
//
//
// 
//
// 
//
// 
function EditarEspecialista() {
// 
//
// 
//
//
// 
//
    // const location = useLocation();
    const { state } = useLocation();
    const [loading, setLoading] = useState(true);

  const [form] = Form.useForm();
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [especialidad, setEspecialidadValue] = useState(null);
  const [especialistaData, setespecialistaData] = useState(null);
  const [especialidadesList, setEspecialidadesList] = React.useState([]);
  const [loadingEspacialidades, setLoadingEspecialidades] = useState({
      loading: true,
      disabled: true,
  });




  //TODO - guardar con el id de las especialidades no los nombres
  const onFinish = async (values) => {
    console.log(values)
    dispatch(showLoader(true))
    try {
      
      const payload = {
        // ...values,
        // hora_entrada: values.rango_horario[0].get('hour'),
        // minuto_entrada: values.rango_horario[0].get('minute'),
        // hora_salida: values.rango_horario[1].get('hour'),
        // minuto_salida: values.rango_horario[1].get('minute'),
        dias_laborables: values.dias_laborables,
        // duracion_cita: values.duracion_cita,

        // pacientes_cita: values.pacientes_cita,
        // createdBy: JSON.parse(localStorage.getItem("user")).id
      }
      const response = await adminUpdateEspecialista(payload, state.especialista)
      if (response.success) {
        message.success(response.message)
        dispatch(showLoader(false))
        navigate("/admin/especialistas")
      } else {
        dispatch(showLoader(false))
        message.error(response.message)
      }
      
    } catch (error) {
      dispatch(showLoader(false))
      message.error(error.message)
    }

  };


  const onReset = () => {
    form.resetFields();
  };


  const onCancel = () => {
    navigate("/admin/especialistas")
    // form.setFieldsValue({
    //   note: "Hello world!",
    //   gender: "male",
    // });
  }

  const getEspecialistaData = async (especialistaId) => {

    try {
        const response = await adminGetEspecialistaData(especialistaId)
        if (response.success) {
            console.log(response)

            const entradaDate = new Date()
            const salidaDate = new Date()
            entradaDate.setHours(response.data.hora_entrada, response.data.minuto_entrada, 0, 0)
            salidaDate.setHours(response.data.hora_salida, response.data.minuto_salida, 0, 0)

            response.data.entradaDate = entradaDate
            response.data.salidaDate = salidaDate

            setespecialistaData(response.data)
            console.log(response.data)
        } else {
          throw new Error(response.message)
          return null
        }
      } catch (error) {
        message.error(error.message)
        return null
      }

      setLoading(false)
}



  useEffect(()=>{
    const hayInfo = state === null ? true : false
    if (!hayInfo){
        getEspecialistaData(state.especialista)
    }

    }, [])


    const hayInfo = state === null ? true : false

    if(loading && !hayInfo){
        dispatch(showLoader(true))
        return <div></div>;
    }else {
        dispatch(showLoader(false))
  return (
    //  Verifica si la bandera de navegación autorizada es verdadera
    // const hayInfo = state === null ? true : false
    hayInfo? <Navigate to="/admin/especialistas" /> :
    <Form
      {...layout}
    //   initialValues={especialistaData}
      layout="vertical"
      form={form}
      name="control-hooks"
      onFinish={onFinish}
      size="small"
      style={
        {
          //maxWidth: 600,
        }
      }
    >   

      <Row justify="space-between">

        <Col span={7}>
          <Form.Item
            name="nombre"
            label="Nombre" 
            rules={[
              {
                required: true,
              },
            ]}
            initialValue={especialistaData?.nombre}
          >
            <Input 
                disabled={true} 
                // defaultValue={especialistaData?.nombre}
            />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item
            name="ape_paterno"
            label="Apellido Paterno"
            rules={[
              {
                required: true,
              },
            ]}
            initialValue={especialistaData?.ape_paterno}
          >
            <Input disabled={true} 
                // defaultValue={especialistaData?.ape_paterno}
            />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item
            name="ape_materno"
            label="Apellido Materno"
            rules={[
              {
                required: true,
              },
            ]}
            initialValue={especialistaData?.ape_materno}
          >
            <Input disabled={true} 
                // defaultValue={especialistaData?.ape_materno}
            />
          </Form.Item>
        </Col>

      </Row>
      
      <Form.Item
        {...tailLayout2}
        name="especialidad"
        label="Especialidad"
        rules={[
          {
            required: true,
          },
        ]}
        initialValue={state?.especialidad_nombre}
      >
        <Select
          placeholder="Seleciona la especialidad"
        //   value="definido"
          disabled={true} 
        //   defaultValue={state?.especialidad_nombre}
        >
          
        </Select>
      </Form.Item>
      <Form.Item
        {...tailLayout2}
        name="rango_horario"
        label="Horario"
        rules={[
          {
            required: true,
            message: 'el horario es requerido'
          },
        ]}
        initialValue = {[moment(especialistaData.entradaDate), moment(especialistaData.salidaDate)]}
      >
        <TimePicker.RangePicker 
        disabled={true} 
          placeholder={["Entrada", "Salida"]}
          minuteStep={15}
          format="h:mm A"
          defaultValue = {[moment(especialistaData.entradaDate), moment(especialistaData.salidaDate)]}
        /> 
      </Form.Item>
        <Form.Item
            {...tailLayout2}

            name="dias_laborables"
            label="Dias a Laborar"
            rules={[
            {
                required: true,
            },
            ]}
            initialValue={especialistaData?.dias_laborables}
        >
            
        <Select
        mode="multiple"
        style={{ width: '65%' }}
        placeholder="Selecciona los dias"
        // defaultValue={especialistaData.dias_laborables}
        // onChange={handleChange}
        // options={diasLista}
      >
        {diasLista}
      </Select>
      </Form.Item>

      <Form.Item
        {...tailLayout2}
        initialValue={especialistaData?.duracion_cita}
        name="duracion_cita"
        label="Duracion de Cita (minutos)"
        rules={[
          {
            required: true,
          },
        ]}>
        <Select
        disabled={true} 
          style={{ width: '65%' }}
          placeholder="cuanto dura cada cita"
        //   defaultValue={especialistaData.duracion_cita}
        >
        {duracionCitaLista}
      </Select>
      </Form.Item>

      {/* <Form.Item
        {...tailLayout2}
        name="pacientes_cita"
        label="Cuantos Pacientes Puede atender por Cita"
        rules={[
          {
            required: true,
          },
        ]}>
        <Select
          style={{ width: '65%' }}
          placeholder="# pacientes por cita"
        >
          <Option value={1}>1</Option>,
          <Option value={2}>2</Option>,
          <Option value={3}>3</Option>,
      </Select>
      </Form.Item> */}
      
      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Guardar cambios
        </Button>
        {/* <Button htmlType="button" onClick={onReset}>
          Borrar
        </Button> */}
        <Button type="link" htmlType="button" onClick={onCancel}>
          Cancelar
        </Button>
      </Form.Item>
    </Form>
  )

    } //del if else
}

export default EditarEspecialista
