import { addDo, addDoc, collection, doc, getDoc, getDocs, query, deleteDoc } from "firebase/firestore"
import firestoreDB from "../firebaseConfig"

export const AddEspecialista = async(paylod)=>{
    try {
        await addDoc(collection(firestoreDB, "especialistas"), paylod)
        return {
            success: true,
            message: "Especialista Creado Exitosamente"
        }
    } catch (error) {
        return {
            success: false,
            message: error.message
        }
    }
}


export const GetEspeciallistas = async ()=>{
    try {
        const especialistas = await getDocs(collection(firestoreDB, "especialistas"))
        return {
            success: true,
            data: especialistas.docs.map((doc)=>{
                return {
                    id: doc.id,
                    ...doc.data()
                }
            })
        }
    } catch (error) {
        return {
            success: false,
            message: error.message
        }
    }
}

export const GetEspecialidades = async ()=>{
    try {
        const especialidades = await getDocs(collection(firestoreDB, "especialidades"))
        return {
            success: true,
            data: especialidades.docs.map((doc)=>{
                return {
                    id: doc.id,
                    ...doc.data()
                }
            })
        }
    } catch (error) {
        return {
            success: false,
            message: error.message
        }
    }
}

export const GetEspecialistas= async ()=>{
    try {
        const especialidades = await getDocs(collection(firestoreDB, "especialistas"))
        return {
            success: true,
            data: especialidades.docs.map((doc)=>{
                return {
                    id: doc.id,
                    ...doc.data()
                }
            })
        }
    } catch (error) {
        return {
            success: false,
            message: error.message
        }
    }
}


export const GetEspecialistaData= async (especialistaId)=>{
    try {
        const docRef = doc(firestoreDB, "especialistas", especialistaId)
        const especialistaSnap= await getDoc(docRef)
        return {
            success: true,
            data: especialistaSnap.data()
        }
    } catch (error) {
        return {
            success: false,
            message: error.message
        }
    }
}

export const GetEspecialidadData= async (especialidadId)=>{
    try {
        const docRef = doc(firestoreDB, "especialidades", especialidadId)
        const especiallidadSnap= await getDoc(docRef)
        return {
            success: true,
            data: especiallidadSnap.data(),
        }
    } catch (error) {
        return {
            success: false,
            message: error.message
        }
    }
}

export const GetEspecialistaConsultasData= async (especialistaId, diaFecha)=>{

    try {
        // const consultaRef = collection(firestoreDB, "especialistas", especialistaId, 'consultas', '2023', '07', '18', 'consultas_dias')
        const consultaRef = collection(firestoreDB, "especialistas", especialistaId, 'consultas', diaFecha.getFullYear().toString(), (diaFecha.getMonth()+1).toString().padStart(2, '0'), (diaFecha.getDate()).toString().padStart(2, '0'), 'consultas_dias')
        const q = query(consultaRef)
        console.log(q)
        const querySnapshot = await getDocs(q)
        console.log(querySnapshot)
        const documentos =  querySnapshot.docs.map((doc) => {
            return doc.data()
        });

        return {
            success: true,
            data: documentos
        }
    } catch (error) {
        console.log(error)
        return {
            success: false,
            message: error.message
        }
    }
}


export const adminEliminarEspecialista = async (especialistaId)=>{

    try {
        const documentoRef = doc(firestoreDB, "especialistas", especialistaId)
        await deleteDoc(documentoRef)

        return {
            success: true,
        }
    } catch (error) {
        return {
            success: false,
            message: error.message
        }
    }
}