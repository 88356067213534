import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import useLocation from "react-router-dom"
import { Menu, Layout, theme } from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  // BarChartOutlined,
  // TeamOutlined,
  LogoutOutlined,
  UserOutlined,
  CalendarOutlined,
  SkinOutlined,
  HomeOutlined
} from "@ant-design/icons";



const { Header, Sider, Content } = Layout;

function MenuLayout({ children }) {

  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  // const location = useLocation();
  const [selectedKey, setKey] = useState(false);
 

  useEffect(() => {
    // console.log(location)
    // console.log(window.location.pathname);
    console.log(selectedKey)
    switch (window.location.pathname) {
      case "/admin":
        setKey("1")
        break;
      case "/admin/citas":
        setKey("2")
        break;
      case "/admin/especialistas/":
        setKey("3")
        break;
      case "/admin/especialistas/agregar":
        setKey("3")
        break;
      default:
        break;
    }
  }, [navigate]);


  const handleMenuSelect = (item) => {
    switch (item.key) {
      case "1":
        navigate("/admin");
        setKey("1")
        break;
      case "2":
        navigate("/admin/citas");
        setKey("2")
        break;
      case "3":
        navigate("/admin/especialistas");
        setKey("3")
        break;
      case "4":
        localStorage.removeItem("user");
        setKey("4")
        navigate("/login");
        break;
      default:
        break;
    }
  };

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Layout>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{
          overflow: "auto",
          height: "100vh",
        }}
      >
        <div className="logo" />

        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[selectedKey]}
          // defaultSelectedKeys={["2"]}
          // style={{ height: "100%", borderRight: 0 }}
          // style={{ position: "absolute", bottom: "0" }}
          onSelect={handleMenuSelect}
          items={[
            {
              key: "1",
              icon: <HomeOutlined />,
              label: "Inicio",
            },
            {
              key: "2",
              icon: <CalendarOutlined />,
              label: "Citas",
            },
            {
              key: "3",
              icon: <SkinOutlined />,
              label: "Especialistas",
            },
            // {
            //   key: "4",
            //   icon: <LogoutOutlined style={{ position: "fixed", bottom: "10px" }}/>,
            //   label: <p style={{ position: "fixed", bottom: "10px" }}>Salirpp</p>,
            // },
          ]}
        />
        <Menu
          theme="dark"
          mode="inline"
          onSelect={handleMenuSelect}
          style={{ position: "absolute", bottom: 10 }}
          items={[
            // {
            //   key: "5",
            //   icon: <UserOutlined />,
            //   label: "Perfil",
            // },
            {
              key: "4",
              icon: <LogoutOutlined />,
              label: "Salir",
              danger: true,
            },
          ]}
        />
      </Sider>
      <Layout className="site-layout">
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: () => setCollapsed(!collapsed),
            }
          )}
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
}

export default MenuLayout;
