import { UserAddOutlined } from "@ant-design/icons";
import { Button, Col, Row, Table, message, Tag, Space, Typography, Popconfirm } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { showLoader } from "../../redux/loaderSlice";
import { GetEspeciallistas, GetEspecialidadData, adminEliminarEspecialista } from "../../api_calls/Especialistas";

const diasSemanaNombre = ["Lun", "Mar", "Mier", "Jue", "Vier"]

function EspecialistasHome() {
  const navigate = useNavigate();
  const [especialistas, setEspecialistas] = React.useState([])
  const dispatch = useDispatch()
  const defaultTitle = () => 'Especialistas';
  const defaultFooter = () => '';

  const getData = async () => {
    try {
      dispatch(showLoader(true))
      const response = await GetEspeciallistas()
      dispatch(showLoader(false)) 
      if (response.success) {
        
        // Obtener la lista actualizada con el campo "nuevoCampo"
        const listaActualizada = await Promise.all(
          response.data.map(async (objeto) => {
            const especialidadObjeto = await GetEspecialidadData(objeto.especialidad)

            const entradaDate = new Date()
            const salidaDate = new Date()
            entradaDate.setHours(objeto.hora_entrada, objeto.minuto_entrada, 0, 0)
            salidaDate.setHours(objeto.hora_salida, objeto.minuto_salida, 0, 0)

            return {
              ...objeto,
              docId: objeto.id,
              especialidad_nombre: especialidadObjeto.data.especialidad || "N/A",
              entradaDate: entradaDate.toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true }),
              salidaDate: salidaDate.toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true })
            }
          })
        )

        setEspecialistas(listaActualizada)
      } else {
        throw new Error(response.message)
      }
    } catch (error) {
      dispatch(showLoader(false)) 
      message.error(error.message)
    }
  }



  const eliminarEspecialista = async (record) => { 
    // console.log(record)
    dispatch(showLoader(true))

    try {
      const response = await adminEliminarEspecialista(record.docId)
      if (response.success) {
        getData()
        dispatch(showLoader(false))

      } else {
        dispatch(showLoader(false))
        message.error(response.message)
      }
      
    } catch (error) {
      dispatch(showLoader(false))
      message.error(error.message)
    }
    
  }


  useEffect(()=>{
    getData();
  }, [])

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      key: 'name',
      render: (text) => <span translate="no">{text}</span>
    },
    {
      title: 'Apellido Paterno',
      dataIndex: 'ape_paterno',
      key: 'ape_paterno',
      render: (text) => <span translate="no">{text}</span>
    },
    {
      title: 'Apellido Materno',
      dataIndex: 'ape_materno',
      key: 'ape_materno',
      render: (text) => <span translate="no">{text}</span>
    },
    {
      title: 'Especialidad',
      dataIndex: 'especialidad_nombre',
      key: 'especialidad',
      sorter: (a, b) => a.especialidad_nombre.length - b.especialidad_nombre.length,
      sortDirections: ['descend', 'ascend'],
      render: (text) => <span translate="no">{text}</span>
    },
    {
      title: 'Dias a laborar',
      dataIndex: 'dias_laborables',
      key: 'dias_laborables',
      width: 180,
      render: (_, { dias_laborables }) => (
        <>
          {dias_laborables.map((dia) => {
            // let color = tag.length > 5 ? 'geekblue' : 'green';
            // if (tag === 'loser') {
            //   color = 'volcano';
            // }
            return (
              // <Tag color={color} key={tag}>
              //   {tag.toUpperCase()}
              // </Tag>
              <span translate="no">
              <Tag  key={dia}>
              {diasSemanaNombre[dia-1]}
            </Tag>
            </span>
            );
          })}
        </>
      ),
    },
    {
      title: 'Hora Entrada',
      dataIndex: 'entradaDate',
      key: 'entrada',
      render: (text) => <span translate="no">{text}</span>
    },
    {
      title: 'Hora Ultima Cita',
      dataIndex: 'salidaDate',
      key: 'salida',
      render: (text) => <span translate="no">{text}</span>
    },
    {
      title: 'Duracion (min)',
      dataIndex: 'duracion_cita',
      key: 'duracion_cita',
      width: 90,
    },
    {
      title: 'Action',
      key: 'action',
      width: 90,
      fixed: 'right',
      render: (_, record) => (
        <span style={{ margin: 0, padding: 0 }} >
          <span translate="no">
        <Typography.Link style={{ fontSize: '12px' , display: 'block', color:'#1890ff', marginBottom: '0px'}} 
          // disabled={editingKey !== '' } 
            onMouseEnter={(e) => e.target.style.color = '#06b7af'}
            onMouseLeave={(e) => e.target.style.color = '#1890ff'}
            // onClick={() => nuevaCita(record)}
            onClick={() => {
              // navigate("/admin/especialistas/editar")
              navigate("/admin/especialistas/editar", 
                {state:{
                    especialista:record.docId,
                    especialidad_nombre:record.especialidad_nombre
                    // name:'sabaoon' 
                  }
                }
              )
            }}
        >
            Editar
        </Typography.Link >
        </span>
        <Popconfirm title="Quiere borrarlo(a)?" 
          onConfirm={() => eliminarEspecialista(record)}
        >
           <span translate="no">
            <Typography.Link style={{ fontSize: '12px'  ,color: 'red' }} 
              // disabled={editingKey !== '' } 
                onMouseEnter={(e) => e.target.style.color = 'black'}
                onMouseLeave={(e) => e.target.style.color = 'red'}
                // onClick={() => bloquearCita(record)}
            >
               Eliminar
            </Typography.Link>
            </span>
        </Popconfirm>
    </span>
    
      ),
    },
  ];

  return (
    <>
      <Row 
        style={
          {
            marginBottom:"10px"
          }
        }
        justify="end"
      >
        <Col>
        <Button
          type="primary"
          icon={<UserAddOutlined />}
          onClick={() => {
            navigate("/admin/especialistas/agregar");
          }}
        >
          Agregar Especialista
        </Button>
        </Col>
      </Row>
      <Row>
        {/* <Col span={18} offset={3}> */}
          <Table 
            sticky={true}
            size="small"
            title={defaultTitle}
            footer={defaultFooter}
            columns={columns} 
            dataSource={especialistas}
            scroll={{
              // scrollToFirstRowOnChange:true,
              // x: "max-content",
              // y: "max-content",
              x: 1000,
              y: 430,
              // x:"10%",
              // y:"10%"
              }}
          />
        {/* </Col> */}
      </Row>
    </>
  );
}

export default EspecialistasHome;
