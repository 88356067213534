import React, { useEffect, useState } from "react";
import { Button, Form, Input, Col, Row, Select, Space, message, TimePicker } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showLoader } from "../../../redux/loaderSlice";
import { AddEspecialista, GetEspecialidades } from "../../../api_calls/Especialistas";
import FormItem from "antd/es/form/FormItem";
import dayjs from 'dayjs'
// import customParseFormat from 'dayjs/plugin/customParseFormat'
import objectSupport from 'dayjs/plugin/objectSupport'

// dayjs.extend(customParseFormat)
dayjs.extend(objectSupport)

const { Option } = Select;
const layout3 = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 24,
  },
};

const layout = {
  labelCol: {
    span: 12,
  },
  wrapperCol: {
    span: 24,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 8,
  },
};
const tailLayout2 = {
  wrapperCol: {
    offset: 0,
    span: 10,
  },
};

const diasLista = [
  <Option value={1}>Lunes</Option>,
  <Option value={2}>Martes</Option>,
  <Option value={3}>Miercoles</Option>,
  <Option value={4}>Jueves</Option>,
  <Option value={5}>Viernes</Option>,
  <Option value={6}>Sabado</Option>,
  <Option value={0}>Domingo</Option>,
]

const duracionCitaLista = [
  <Option value={15}>15</Option>,
  <Option value={30}>30</Option>,
  <Option value={40}>40</Option>,
  <Option value={45}>45</Option>,
  <Option value={60}>60</Option>,
]

function AgregarEspecialista() {

  const [form] = Form.useForm();
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [especialidad, setEspecialidadValue] = useState(null);
  const [especialidadesList, setEspecialidadesList] = React.useState([]);
  const [loadingEspacialidades, setLoadingEspecialidades] = useState({
      loading: true,
      disabled: true,
  });

  const getEspecialiadadesData = async () => {
    try {
    //   dispatch(showLoader(true))
        setLoadingEspecialidades({
            ...loadingEspacialidades,
            loading: false,
            disabled: false,
        });
        const response = await GetEspecialidades()
        //   dispatch(showLoader(false)) 
        if (response.success) {
            // message.success(response.message);
            //TODO - agregar logica de solo mostrar espcialidades con estado activo
            const lista = response.data.map((option) => {
                return (
                    <Option value={option.id}>
                        {option.especialidad}
                    </Option>
                )
            });
            setEspecialidadesList(lista)
            setLoadingEspecialidades({
                ...loadingEspacialidades,
                loading: false,
                disabled: false,
            });
        } else {
            throw new Error(response.message)
        }
        } catch (error) {
        //   dispatch(showLoader(false)) 
        message.error(error.message)
        setLoadingEspecialidades({
            ...loadingEspacialidades,
            loading: false,
            disabled: false,
        });
    }
}


  //TODO - guardar con el id de las especialidades no los nombres
  const onFinish = async (values) => {
    // const tiempoEntrada = dayjs().set('hour', 5).set('minute', 55)
    // const tiempoEntrada= dayjs('13:30:56', 'HH:mm:ss')
    // console.log(values);
    // console.log("hora entrada", values.rango_horario[0].get('hour'))
    // console.log("minuto entrada", values.rango_horario[0].get('minute'))
    // console.log(tiempoEntrada)
    try {
      dispatch(showLoader(true))
      const payload = {
        // ...values,
        nombre: values.nombre,
        ape_paterno: values.ape_paterno,
        ape_materno: values.ape_materno,
        especialidad: values.especialidad,
        hora_entrada: values.rango_horario[0].get('hour'),
        minuto_entrada: values.rango_horario[0].get('minute'),
        hora_salida: values.rango_horario[1].get('hour'),
        minuto_salida: values.rango_horario[1].get('minute'),
        dias_laborables: values.dias_laborables,
        duracion_cita: values.duracion_cita,
        // pacientes_cita: values.pacientes_cita,
        createdBy: JSON.parse(localStorage.getItem("user")).id
      }
      const response = await AddEspecialista(payload)
      if (response.success) {
        message.success(response.message)
        navigate("/admin/especialistas")
      } else {
        message.error(response.message)
      }
      dispatch(showLoader(false))
    } catch (error) {
      dispatch(showLoader(false))
      message.error(error.message)
    }

  };
  const onReset = () => {
    form.resetFields();
  };
  const onCancel = () => {
    navigate("/admin/especialistas")
    // form.setFieldsValue({
    //   note: "Hello world!",
    //   gender: "male",
    // });
  };

  useEffect(()=>{
    getEspecialiadadesData();
  }, [])

  return (
    <Form
      {...layout}
      layout="vertical"
      form={form}
      name="control-hooks"
      onFinish={onFinish}
      size="small"
      style={
        {
          //maxWidth: 600,
        }
      }
    >
      <Row justify="space-between">
        <Col span={7}>
          <Form.Item
            name="nombre"
            label="Nombre"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item
            name="ape_paterno"
            label="Apellido Paterno"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item
            name="ape_materno"
            label="Apellido Materno"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

      </Row>
      
      <Form.Item
        {...tailLayout2}
        name="especialidad"
        label="Especialidad"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          placeholder="Seleciona la especialidad"
          value={especialidad}
        >
          {especialidadesList}
        </Select>
      </Form.Item>
      <Form.Item
        {...tailLayout2}
        name="rango_horario"
        label="Horario (hora de entrada y hora de ultima cita)"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <TimePicker.RangePicker 
          placeholder={["Entrada", "Salida"]}
          minuteStep={15}
          format="h:mm A"/>
      </Form.Item>
      <Form.Item
        {...tailLayout2}
        name="dias_laborables"
        label="Dias a Laborar"
        rules={[
          {
            required: true,
          },
        ]}>
        <Select
        mode="multiple"
        style={{ width: '65%' }}
        placeholder="Selecciona los dias"
        // defaultValue={['a10', 'c12']}
        // onChange={handleChange}
        // options={diasLista}
      >
        {diasLista}
      </Select>
      </Form.Item>

      <Form.Item
        {...tailLayout2}
        name="duracion_cita"
        label="Duracion de Cita (minutos)"
        rules={[
          {
            required: true,
          },
        ]}>
        <Select
          style={{ width: '65%' }}
          placeholder="cuanto dura cada cita"
        >
        {duracionCitaLista}
      </Select>
      </Form.Item>

      {/* <Form.Item
        {...tailLayout2}
        name="pacientes_cita"
        label="Cuantos Pacientes Puede atender por Cita"
        rules={[
          {
            required: true,
          },
        ]}>
        <Select
          style={{ width: '65%' }}
          placeholder="# pacientes por cita"
        >
          <Option value={1}>1</Option>,
          <Option value={2}>2</Option>,
          <Option value={3}>3</Option>,
      </Select>
      </Form.Item> */}
      
      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Agregar Especialista
        </Button>
        {/* <Button htmlType="button" onClick={onReset}>
          Borrar
        </Button> */}
        <Button type="link" htmlType="button" onClick={onCancel}>
          Cancelar
        </Button>
      </Form.Item>
    </Form>
  );
}

export default AgregarEspecialista;
