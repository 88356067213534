import { Button, Form, message } from "antd";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LoginUser } from "../../api_calls/users";
import { useDispatch } from "react-redux";
import { showLoader } from "../../redux/loaderSlice";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onFinish = async (values) => {
    // console.log(values);
    try {
      dispatch(showLoader(true))
      // se regresa el objeto
      const response = await LoginUser(values);
      dispatch(showLoader(false))
      if (response.success) {
        message.success(response.message);
        // console.log(response.data);
        // se guarda en el local storage
        //TODO - hacer que se encriptado
        localStorage.setItem(
          "user",
          JSON.stringify({
            ...response.data,
            password: "",
          })
        );
        navigate("/admin");
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      dispatch(showLoader(false))
      message.error(error.message);
    }
  };

  // navegar al admin home si ya este loggeado
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user"));
    if (userData) navigate("/admin");
  }, []);


  return (<> {
    !JSON.parse(localStorage.getItem("user")) ? (
      <div className="flex justify-center items-center h-screen">
        <Form
          layout="vertical"
          className="w-400 bg-white p-2"
          onFinish={onFinish}
        >
          <h1 className="txt-center txt-uppercase">
            <strong>Ingresar</strong>
          </h1>
          {/* <hr/> */}

          <Form.Item
            label="Correo"
            name="correo"
            rules={[
              {
                required: true,
                message: "Entra tu correo",
              },
            ]}
          >
            <input type="email"></input>
          </Form.Item>
          <Form.Item
            label="Contraseña"
            name="password"
            rules={[
              {
                required: true,
                message: "Entra tu contraseña",
              },
            ]}
          >
            <input type="password"></input>
          </Form.Item>
          <Button type="primary" block htmlType="submit">
            Entrar
          </Button>
          {/* <button className="btn-contained" type="submit">Registrar</button> */}
          {/* <p>
            No tienes una cuenta?
            <Link to="/register">
              <strong> Crear una Cuenta</strong>
            </Link>
          </p> */}
        </Form>
      </div>
    ) : (null) }
    </>
  )
}

export default Login;
