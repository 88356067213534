import { addDo, addDoc, collection, deleteDoc, doc, getDoc, getDocs, query } from "firebase/firestore"
import firestoreDB from "../firebaseConfig"


export const adminGetCitas = async (especialista, year, mes, dia)=>{

    try {
        const consultaRef = collection(firestoreDB, "especialistas", especialista, 'consultas', year.toString(), mes.toString().padStart(2, '0'), dia.toString().padStart(2, '0'), 'consultas_dias')
        const q = query(consultaRef)

        const querySnapshot = await getDocs(q)
        const documentos =  querySnapshot.docs.map((doc) => {
            const data = doc.data()
            data.docId = doc.id
            return data
        });

        return {
            success: true,
            data: documentos
        }
    } catch (error) {
        console.log(error)
        return {
            success: false,
            message: error.message
        }
    }
}


export const adminEliminarCita = async (especialista, year, mes, dia, citaid)=>{

    try {
        const documentoRef = doc(firestoreDB, "especialistas", especialista, 'consultas', 
            year.toString(), mes.toString().padStart(2, '0'), dia.toString().padStart(2, '0'), 
            'consultas_dias', citaid)
        await deleteDoc(documentoRef)

        return {
            success: true,
        }
    } catch (error) {
        return {
            success: false,
            message: error.message
        }
    }
}


export const adminEliminarDia = async (especialista, year, mes, dia)=>{

    try {
        const collectionRef = collection(firestoreDB, "especialistas", especialista, 'consultas', 
            year.toString(), mes.toString().padStart(2, '0'), dia.toString().padStart(2, '0'), 
            'consultas_dias')


        const querySnapshot = await getDocs(collectionRef);
        querySnapshot.forEach((doc) => {
             deleteDoc(doc.ref);
        })

        return {
            success: true,
        }
    } catch (error) {
        return {
            success: false,
            message: error.message
        }
    }
}