import { Carousel, Col,  Menu, Row, Typography, Alert} from 'antd';
import { AppstoreOutlined, MailOutlined, SettingOutlined , UserOutlined} from '@ant-design/icons';
import React, { useEffect, useState } from "react";
import AgendarForm from '../../components/agendarForm';
import firestoreDB from '../../firebaseConfig';
import firestoreStorage from '../../firestoreConfig';
import { addDo, addDoc, collection, doc, getDoc, getDocs, query } from "firebase/firestore"
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { initializeApp } from "firebase/app";
import { useNavigate } from 'react-router-dom';



const { Title, Text } = Typography;


const items = [
  {
    label: 'Login',
    key: 'login',
    icon: <UserOutlined />,
  },
  // {
  //   label: 'Navigation Two',
  //   key: 'app',
  //   icon: <AppstoreOutlined />,
  //   disabled: true,
  // },
  // {
  //   label: 'Navigation Three - Submenu',
  //   key: 'SubMenu',
  //   icon: <SettingOutlined />,
  //   children: [
  //     {
  //       type: 'group',
  //       label: 'Item 1',
  //       children: [
  //         {
  //           label: 'Option 1',
  //           key: 'setting:1',
  //         },
  //         {
  //           label: 'Option 2',
  //           key: 'setting:2',
  //         },
  //       ],
  //     },
  //     {
  //       type: 'group',
  //       label: 'Item 2',
  //       children: [
  //         {
  //           label: 'Option 3',
  //           key: 'setting:3',
  //         },
  //         {
  //           label: 'Option 4',
  //           key: 'setting:4',
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   label: (
  //     <a href="https://ant.design" target="_blank" rel="noopener noreferrer">
  //       Navigation Four - Link
  //     </a>
  //   ),
  //   key: 'alipay',
  // },
];




function Home() {
  const navigate = useNavigate();
  const [current, setCurrent] = useState('mail');
  const [images, setImages] = useState([]);
  const [spaceAboveRow, setSpaceAboveRow] = useState(window.innerWidth >= 992 ? 20 : 0);
  const [letraTitulo, setLetraTitulo] = useState(window.innerWidth < 768 ? 12 : 22);
  const [pantallaCelular, setPantallaCelular] = useState(window.innerWidth < 768 ? true : false);
  
  // Obtener la colección de imágenes desde Firestore (reemplaza "imagenesCollection" con el nombre real de tu colección)
  const fetchImages = async () => {
    const querySnapshot = await getDocs(collection(firestoreDB, "imagenes"));
    const imageUrls = querySnapshot.docs.map((doc) => doc.data().url);
    setImages(imageUrls);
    console.log(imageUrls)
  };

  const onClick = (e) => {
    navigate("/login")
    setCurrent(e.key);
  };

  const contentStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%', // Cambiado a 100% para ocupar todo el ancho del contenedor
    height: 'auto',
    color: '#fff',
    // lineHeight: '260px',
    textAlign: 'center',
    background: '#364d79',
  };

  useEffect(() => {
  // Obtener la referencia al bucket del Storage (reemplaza "images" con el nombre real de tu bucket)
  const storageRef = ref(firestoreStorage)
  console.log(storageRef)

  // Obtener la lista de todas las imágenes dentro del bucket
  listAll(storageRef)
    .then((res) => {
      const imageUrls = res.items.map((item) =>
        getDownloadURL(item).catch((error) => {
          console.log("Primer error", error);
          return null;
        })
      )
      console.log("images")
      console.log(imageUrls)
      return Promise.all(imageUrls);
    })
    .then((downloadUrls) => {
      // Filtrar las URL que no pudieron obtenerse correctamente
      const filteredUrls = downloadUrls.filter((url) => url !== null);
      setImages(filteredUrls);
    })
    .catch((error) => {
      console.log("Error al obtener las imágenes:", error);
    });

    // Detectar cambios en el ancho de la ventana y actualizar el espacio según sea necesario
    const handleResize = () => {
      setSpaceAboveRow(window.innerWidth >= 992 ? 20 : 0);
      setLetraTitulo(window.innerWidth < 768 ? 14 : 22);
      setPantallaCelular(window.innerWidth < 768 ? true : false);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };



  }, []);

  

  return (

    

    <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
<Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" style={{ position: 'relative', zIndex:1}}>
  {/* Aquí están los elementos del menú */}
  {items.map(item => (
    <Menu.Item key={item.key} icon={item.icon}>
      {item.label}
    </Menu.Item>
  ))}
</Menu>

  <div style={{ position: 'absolute', top: pantallaCelular ? 15 : 10,  transform: pantallaCelular ? 'translateX(18%)' : 'translateX(10%)', textAlign: 'center', width: '80%' , zIndex:2}}>
    <p className="titulo-pagina-home" style={{ fontSize: letraTitulo +'px', color: '#0d4f9b', margin: 0 , letterSpacing:  pantallaCelular ? '0px' : '2px'}}>
      <span style={{ color: '#06b7af', fontWeight: '800' }}>Facultad de Enfermería</span> <span style={{ color: 'black', fontWeight: '800' }}> - </span> ENSAIN
    </p>
  </div>

{/* Espacio solo en pantallas grandes */}
<div style={{ height: spaceAboveRow + 'px' }} />

      <div style={{ flexGrow: 1 }}>
      <Row justify="center" 
      align="middle" 
      // style={{ minHeight: '100vh' }}
      
      >
        <Col 
        span={24} md={18} lg={16} xl={14}
          // span={window.innerWidth < 768 ? 24 : 18} 
          // offset={window.innerWidth < 768 ? 0 : 1}
          style={{ textAlign: 'center' }}
        >
          <Carousel autoplay 
             dotPosition="top"
            style={{
              marginBottom: '10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center', // Agregado para centrar verticalmente
            }}
          >
            {images.map((imageUrl, index) => (
              <div key={index}>
                <img src={imageUrl} alt={`Imagen ${index}`} style={{...contentStyle , 
                    objectFit: "contain",
                    maxWidth: '100%', // Cambiado a 70% para centrarse horizontalmente
                    // maxWidth: window.innerWidth < 768 ? "100%" : "70%",
                  }} />
              </div>
            ))}
          </Carousel>
        </Col>

        <Col
        pan={24} md={10} 
          // offset={2}
        // span={window.innerWidth < 768 ? 24 : 10} 
        // offset={window.innerWidth < 768 ? 0 : 2}
        style={ 
          {
              // display: 'flex', 
              justifyContent: 'center'
          }
        }
        > 
          {/* <AgendarForm/> */}
          <div style={{ padding: '20px', maxWidth: '300px', margin: '0 auto' }}>
            <Alert
              message=""
              description={
                <div>
                  <p>Para Agendar Tu Cita Comunicate A:</p>
                  <p style={{ fontSize: '24px', fontWeight: 'bold' }}>442-192-12-00</p>
                  <p>EXT. 5713</p>
                </div>
              }
              type="warning"
              showIcon
            />
          </div>


        </Col>

        
        
        
      </Row>
      </div>

{/* <div style={{ width: '100%', background: 'blue', height: '50px' }}></div> */}

<footer style={{ background: '#06b7af', padding: '20px 10px', color: '#fff', textAlign: 'center', marginTop: '40px'}}>
<Text>Ensain © 2023 Facultad de Enfermeria - UAQ | Todos los derechos reservados</Text>
</footer> 

    </div>
  )
}

export default Home


//  <footer style={{ position: 'fixed', bottom: 0, left: 0, right: 0, background: '#06b7af', padding: '20px 0', color: '#fff', textAlign: 'center', marginTop: '40px'}}>
// <Text>Ensain © 2023 Facultad de Enfermeria - UAQ | Todos los derechos reservados</Text>
// </footer> 


// Para pantallas grandes

{/* <div>
<Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
<Col span={12} style={{ textAlign: 'center' }}>
    <Carousel autoplay 
      style={{
        marginBottom: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center', // Agregado para centrar verticalmente
      }}
    >
      {images.map((imageUrl, index) => (
        <div key={index}>
          <img src={imageUrl} alt={`Imagen ${index}`} style={{...contentStyle , 
              objectFit: "contain",
              maxWidth: '70%', // Cambiado a 70% para centrarse horizontalmente
              // maxWidth: window.innerWidth < 768 ? "100%" : "70%",
            }} />
        </div>
      ))}
    </Carousel>
    </Col>
  <Col
  // span={12} offset={6}
   style={ 
    {
       display: 'flex', 
        justifyContent: 'center'
    }
   }
  > 
    <AgendarForm/>
  </Col>

</Row>
</div>




 */}
