import { addDo, addDoc, collection, doc, getDoc, getDocs, query, updateDoc } from "firebase/firestore"
import firestoreDB from "../firebaseConfig"


export const adminGetEspecialidades = async ()=>{
    try {
        const especialidades = await getDocs(collection(firestoreDB, "especialidades"))
        return {
            success: true,
            data: especialidades.docs.map((doc)=>{
                return {
                    id: doc.id,
                    ...doc.data()
                }
            })
        }
    } catch (error) {
        return {
            success: false,
            message: error.message
        }
    }
}


export const adminGetEspecialistas= async ()=>{
    try {
        const especialidades = await getDocs(collection(firestoreDB, "especialistas"))
        return {
            success: true,
            data: especialidades.docs.map((doc)=>{
                return {
                    id: doc.id,
                    ...doc.data()
                }
            })
        }
    } catch (error) {
        return {
            success: false,
            message: error.message
        }
    }
}


export const adminGetEspecialistaData= async (especialistaId)=>{
    try {
        const docRef = doc(firestoreDB, "especialistas", especialistaId)
        const especialistaSnap= await getDoc(docRef)
        return {
            success: true,
            data: especialistaSnap.data()
        }
    } catch (error) {
        return {
            success: false,
            message: error.message
        }
    }
}


export const adminUpdateEspecialista = async (payload, especialistaId) => {
    try {
      const docRef = doc(firestoreDB,"especialistas", especialistaId);
      await updateDoc(docRef, payload);
      console.log("Documento actualizado correctamente");
      return {
        success: true,
        message: "Cambios guardados"
        }
    } catch (error) {
      console.error("Error al actualizar el documento:", error);
      return {
        success: false,
        message: error.message
        }
    }
  }