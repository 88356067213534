import {
    Button,
    Card,
    Form,
    Input,
    Radio,
    Select,
    Space,
    TimePicker,
    message,
    Spin,
    Modal,
    Menu,
    Table,
    Popconfirm,
    Dropdown, 
    Typography,
    InputNumber,
    Row,
    Col,
} from "antd";
import { ReloadOutlined } from '@ant-design/icons';
import { DownOutlined } from '@ant-design/icons'
import { Option } from "antd/es/mentions";
import React, { useEffect, useState, Component, useRef } from "react"
import { useDispatch } from "react-redux"
import { adminGetCitas, adminEliminarCita, adminEliminarDia } from "../api_calls/citas";
import {adminGetEspecialistaData} from "../api_calls/especialidades"
import {
    AdminAddCita, AdminBloquearCita, AdminAddCitaCustom
} from "../api_calls/agendar";

import locale_esp from 'antd/es/date-picker/locale/es_ES'; 

//   const menu = (
// <Menu>
//     <Menu.Item as="a" key="1" danger={true} disabled={true}>
//       <Popconfirm
//       placement="top"
//         title="Cancelar cita?"
//         onConfirm={() => message.info('did menu action')}
//       >
//         Cancelar?
//       </Popconfirm>
//     </Menu.Item>


//     <Menu.Item as="a" key="2" disabled={true}>
//       <Popconfirm
//       placement="top"
//         title="Bloquear hora?"
//         onConfirm={() => message.info('did menu action')}
//       >
//         Bloquear
//       </Popconfirm> 
//     </Menu.Item>
//     <Menu.Item as="a" key="3">
//       <Popconfirm
//       placement="top"
//         title="Agregar Cita?"
//         onConfirm={() => message.info('did menu action')}
//       >
//         Nuevo
//       </Popconfirm>
//     </Menu.Item>

//   </Menu>
//   )




function generarListaFechas(fecha, horaInicial, minutoInicial, horaFinal, minutoFinal, duracion) {
    const listaFechas = [];
    console.log("TABLA - FECHA")
    console.log(fecha)
    const fechaActual = fecha;

    const fechaInicio = new Date(fechaActual)
    const fechaFinal = new Date(fechaActual)

    fechaInicio.setHours(horaInicial)
    fechaInicio.setMinutes(minutoInicial)
    fechaFinal.setHours(horaFinal)
    fechaFinal.setMinutes(minutoFinal)
  
    while (fechaInicio <= fechaFinal){
        listaFechas.push(new Date(fechaInicio))
        fechaInicio.setMinutes(fechaInicio.getMinutes() + duracion)
    }
    // for (let hora = horaInicial; hora <= horaFinal; hora++) {
    //   for (let minuto = minutoInicial; minuto <= minutoFinal; minuto++) {
    //     const nuevaFecha = new Date(fechaActual);
    //     nuevaFecha.setHours(hora, minuto, 0, 0);
    //     listaFechas.push(nuevaFecha);
    //   }
    // }
    return listaFechas
  }

  const compararHoraMinuto = (fecha1, fecha2) => {
    const date1 = new Date(fecha1)
    const date2 = new Date(fecha2)
    return date1.getHours() === date2.getHours() && date1.getMinutes() === date2.getMinutes()
  };


  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    // InputNumber
    const inputNode = inputType === 'number' ? <Input /> : (dataIndex === 'tipo' || dataIndex === 'estado') ? <Input disabled /> : <Input />;

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Ingresa ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };


//
//
//
//
//
//
//
//
//
//
//
const AdminTablaCita = (props) => {
//
//
//
    const [reloadCount, setReloadCount] = useState(0);
    const [form] = Form.useForm();
    const [formRef] = Form.useForm();
    const [data, setData] = useState([]);
    const [citas, setCitas] = React.useState([])
    const [loading, setLoading] = useState(false)
    const [fechaActual, setFechaActual] = useState(new Date(props.year, props.mes, props.dia))

    const [editingKey, setEditingKey] = useState('');
    const isEditing = (record) => record.key === editingKey;

    const [isModalVisible, setIsModalVisible] = useState(false);
    // const formRef = useRef();

    const showModal = () => {
      setIsModalVisible(true);
    }

    const handleCancel = () => {
      formRef.resetFields()
      formRef.resetFields(['fecha', 'apellido', 'nombre'])
      setIsModalVisible(false);
     
    }

    const handleOk = () => {
      // Submit hace llamar el onFinish
      formRef.current.submit();
    }

    //del form
    const onFinish = (nuevoObjeto) => {
      setLoading(true)
      // //console.log("Form values:", objeto);
      // Sustituir el campo 'fecha' por el objeto Date
      nuevoObjeto.fecha = nuevoObjeto.fecha.toDate();
      nuevoObjeto.fecha.setFullYear(props.year)
      nuevoObjeto.fecha.setMonth(props.mes)
      nuevoObjeto.fecha.setDate(props.dia)
      nuevoObjeto.especialidad = props.servicio
      nuevoObjeto.especialista = props.especialista
      nuevoObjeto.estado = "confirmado"
      nuevoObjeto.tipo = "seguimiento"
      // marcar que es una cita con hora especifica o custom
      nuevoObjeto.custom = true
      const hora= nuevoObjeto.fecha.toLocaleTimeString("en-US", { hour: '2-digit', minute: '2-digit', hour12: true })
      nuevoObjeto.hora = hora
      //console.log("nuevos values:", nuevoObjeto);
      //console.log(citas);
      // buscar la posicion o index donde deberia de ir la nueva cita
      let insertIndex = citas.findIndex(
        (objeto) => objeto.fecha > nuevoObjeto.fecha
      );
      
      if (insertIndex === -1) {
        insertIndex = citas.length;
      }

      // genera  nueva lista con las citas actulizadas y formadas
      const listaActualizada = [
        ...citas.slice(0, insertIndex),
        { ...nuevoObjeto, key: insertIndex  },
        ...citas.slice(insertIndex).map((objeto, index) => ({
          ...objeto,
          key: insertIndex + 1 + index,
        })),
      ];
      
      // ahora guardamos en firebase
      AdminAddCitaCustom(listaActualizada[insertIndex], listaActualizada[insertIndex].fecha).then((object) => {
        listaActualizada[insertIndex].docId = object.docId
        setCitas(listaActualizada)
        setData(listaActualizada)
        setLoading(false)
        setIsModalVisible(false); // Close the modal after form submission
        formRef.resetFields()
        formRef.resetFields(['fecha', 'apellido', 'nombre'])
      }).catch((error) => {
          setLoading(false)
          message.error(error.message)
      })

      //console.log(listaActualizada)
      
    };

    const disabledMinutes = () => {
      const minutes = [];
      for (let i = 0; i < 60; i++) {
        if (i % 10 === 0 || i % 10 === 5) {
          // Filtrar los minutos que terminen con 0 y 5
          minutes.push(i);
        }
      }
      return minutes;
    };


    const nuevaCita = (record) => {
        form.setFieldsValue({
            nombre: '',
            apellido: '',
            estado: 'confirmado',
            tipo: 'seguimiento',
            pacienteCelular: '',
            ...record,
        });
        setEditingKey(record.key);
    }

    const bloquearCita = (record) => {
        setEditingKey(record.key);
    }

    const cancel = () => {
        setEditingKey('');
        //console.log('hey se cancelo')
    }

    const eliminarCita = async (especialistaId, year, mes, dia, citaId) => {
      setLoading(true)
        const camposMantener = ["fecha", "hora", "key", "custom"]
        // Definimos la condición
        const condicion = (elemento) => elemento.docId === citaId
        // Encontrar el índice del elemento que cumple la condición
        const indiceElementoEliminar = citas.findIndex(condicion);

        try {
            const response = await adminEliminarCita(especialistaId, year, mes, dia, citaId)
            if (response.success) {
                //console.log("documento elimminado")
                const objetoModificado = Object.fromEntries(Object.entries(citas[indiceElementoEliminar]).filter(([campo, valor]) => camposMantener.includes(campo)))
                const listaModificada = [...citas.slice(0, indiceElementoEliminar), objetoModificado, ...citas.slice(indiceElementoEliminar + 1)]
                // Eliminar objetos con campo "custom" igual a true
                if(objetoModificado.custom === true)
                {
                  listaModificada.splice(indiceElementoEliminar, 1)
                  
                }
                // const listaFiltrada = listaModificada.filter((objeto) => objeto.custom !== true)
                const listaFiltrada = listaModificada

                // Actualizar los ids basados en su posición en la lista
                const listaActualizada = listaFiltrada.map((objeto, index) => ({
                  ...objeto,
                  id: index,
                }));

                setCitas(listaActualizada)
                setData(listaActualizada)
                setLoading(false)
            } else {
                throw new Error(response.message)
                setLoading(false)
            }
        } catch (error) {
            //console.log(error)
            message.error(error.message)
            setLoading(false)
        }
    }

    const eliminar = (record) => {
      
        //console.log("se ejecuta elimnar")
        //console.log(record)
        eliminarCita(props.especialista, props.year, props.mes+1, props.dia, record.docId)
        
    }

    const save = async (key) => {
        try {
          setLoading(true)
          const row = await form.validateFields();
          const newData = [...data];
          const index = newData.findIndex((item) => key === item.key);
          if (index > -1) {
            const item = newData[index];
            newData.splice(index, 1, {
              ...item,
              especialista: props.especialista,
              especialidad: props.servicio,
              ...row,
            });
            setData(newData);
            setCitas(newData)
            setEditingKey('');
          } else {
            // est caso se supone que nunca va a pasar, es por si no hay anda en la tabala
            // y se agrega la primera fila
            newData.push(row);
            setEditingKey('');
            }
            //console.log('aqui ya se guadro')
            //console.log(newData)

            AdminAddCita(newData[key], newData[key].fecha).then((object) => {
                newData[key].docId = object.docId
                setLoading(false)
            }).catch((error) => {
              setLoading(false)
                message.error(error.message)
            })
            setData(newData);
            setCitas(newData)
            
            
        } catch (errInfo) {
          setLoading(false)
          //console.log('error al generar la nueva cita en el form', errInfo);
        }
      }
    
      const eliminarDiaAgendarDia = async (especialidad, especialistaId, year, mes, dia) => {
        setLoading(true)
        try {
          const response = await adminEliminarDia(especialistaId, year, mes, dia)
          if (response.success) {
              //console.log("dia ya eliminado ahora a bloquear")
              const citasBloqueado = citas.map((objeto) => ({
                 ...objeto,
                fecha: objeto.fecha,
                nombre: 'bloqueado',
                tipo: 'bloqueado',
                estado: 'bloqueado',
                apellido: '',
                pacienteCelular: '',
                especialista: especialistaId,
                especialidad: especialidad
              }))
              const citasGuardar = citas.map((objeto)=> ({
                fecha: objeto.fecha,
                nombre: 'bloqueado',
                tipo: 'bloqueado',
                estado: 'bloqueado',
                apellido: '',
                pacienteCelular: '',
                especialista: especialistaId,
                especialidad: especialidad
              }))
            
              //console.log(citasBloqueado)
              //console.log(citasGuardar)
              
              for (const [index, cita] of citasGuardar.entries()) {
                await AdminAddCita(cita, cita.fecha).then((cita) => {
                  citasBloqueado[index].docId = cita.docId
                }).catch((error) => {
                    message.error(error.message)
                })
              }
              setData(citasBloqueado);
              setCitas(citasBloqueado)
              setLoading(false)
              //console.log('dia bloqueado')
          } else {
              throw new Error(response.message)
              setLoading(false)
             
          }
        } catch (error) {
            //console.log(error)
            message.error(error.message)
            setLoading(false)
        }
      }
    
    const bloquearDia = async () => {
    
      eliminarDiaAgendarDia(props.servicio, props.especialista, props.year, props.mes+1, props.dia)
      
    }



    const bloquearHora = async (record) => {
        setLoading(true)
        const newData = [...data];
        const index = newData.findIndex((item) => record.key === item.key)

        newData[index].especialista = props.especialista
        newData[index].especialidad = props.servicio
        newData[index].estado =  "bloqueado"
        newData[index].nombre = "bloqueado"
        newData[index].tipo = "bloqueado"


        AdminBloquearCita( newData[index],  newData[index].fecha).then((object) => {
            // setLoading(false)
            // setIsModalVisible(false)
            newData[index].docId = object.docId
            setData(newData);
            setCitas(newData)
            setEditingKey('');
            setLoading(false)
        }).catch((error) => {
            // //console.log(error.message)
            // setLoading(false)
            // setIsModalVisible(false)
            message.error(error.message)
            setLoading(false)
        })

    }


    const columns = [
        {
          title: 'Hora',
          width: 110,
          dataIndex: 'hora',
          key: 'hora',
          fixed: 'left',
          render: (text) => <span translate="no">{text}</span>
        },
        {
          title: 'Nombre',
          dataIndex: 'nombre',
          key: 'nombre',
          editable: 'true',
          render: (text) => <span translate="no">{text}</span>
        },
        {
            title: 'Apellido',
            dataIndex: 'apellido',
            key: 'apellido',
            editable: 'true',
            render: (text) => <span translate="no">{text}</span>
          },
        {
          title: 'Tipo de Sesion',
          dataIndex: 'tipo',
          key: 'sesion',
          editable: 'true',
          render: (text) => <span translate="no">{text}</span>
        },
        {
          title: 'Telefono',
          dataIndex: 'pacienteCelular',
          key: 'telefono',
          editable: 'true',
          render: (text) => <span translate="no">{text}</span>
        },
        {
            title: 'Estado',
            dataIndex: 'estado',
            key: 'estado',
            editable: 'true',
            render: (text) => <span translate="no">{text}</span>
          },

          {
            title: 'Facultad',
            dataIndex: 'facultad',
            key: 'facultad',
            // editable: 'true',
            render: (text) => <span translate="no">{text}</span>
          },
        {
          title: 'Accion',
          key: 'operation',
          fixed: 'right',
          width: 110,
          render: (_, record) => {
            const editing = isEditing(record)
            const noHayRecord = !('nombre' in record) // Verificar si la hora ya tiene una cita

            return editing ? (
              <span>
                <Popconfirm title="Guardar?" onConfirm={() => save(record.key)}>
                  <a>Guardar</a>
                </Popconfirm>
                <Typography.Link 
                  onClick={() => cancel()}
                  style={{
                    display: 'block'
                    // marginRight: 8,
                  }}
                >
                  Cancelar
                </Typography.Link>
              </span>
            ) : noHayRecord ? (
                <span style={{ margin: 0, padding: 0 }}>
                    <Typography.Link style={{ fontSize: '12px' , display: 'block', color:'#1890ff', marginBottom: '0px'}} disabled={editingKey !== '' } 
                        onMouseEnter={(e) => e.target.style.color = '#06b7af'}
                        onMouseLeave={(e) => e.target.style.color = '#1890ff'}
                        onClick={() => nuevaCita(record)}
                    >
                        Nueva Cita
                    </Typography.Link >
                    <Popconfirm title="Bloquear Hora?" onConfirm={() => bloquearHora(record)}>
                        <Typography.Link style={{ fontSize: '12px'  ,color: 'red' }} disabled={editingKey !== '' } 
                            onMouseEnter={(e) => e.target.style.color = 'black'}
                            onMouseLeave={(e) => e.target.style.color = 'red'}
                            // onClick={() => bloquearCita(record)}
                        >
                            Bloquear Hora
                        </Typography.Link>
                    </Popconfirm>
                </span>
             
              
            ) : 
                <Popconfirm title="Cacnelar Cita?" onConfirm={() => eliminar(record)}>
                    <Typography.Link style={{ color: 'red' }} 
                        onMouseEnter={(e) => e.target.style.color = 'black'}
                        onMouseLeave={(e) => e.target.style.color = 'red'}
                    >
                        Cancelar
                    </Typography.Link>
                </Popconfirm>
        }
        },
      ]

    const getEspecialistaData = async (especialistaId) => {
        //console.log('se inicia getEspecialistaData')
        try {
            const response = await adminGetEspecialistaData(especialistaId)
            if (response.success) {
                //console.log('se termina getEspecialistaData')
                return response.data
            } else {
              throw new Error(response.message)
              return null
            }
          } catch (error) {
            message.error(error.message)
            return null
          }
    }

    const getCitasData = async (especialistaId, year, mes, dia) => {
        //console.log('se hace query de citas del dia')
        try {
            // dispatch(showLoader(true))
            const response = await adminGetCitas(especialistaId, year, mes, dia)
            // dispatch(showLoader(false)) 
            if (response.success) {
            //   setCitas(response.data)
                //console.log(response.data)
                const responseFechaConvertida = response.data.map((objeto) => ({
                    ...objeto,
                    fecha: objeto.fecha.toDate(),
                }))
                return responseFechaConvertida
            } else {
              throw new Error(response.message)
            }
          } catch (error) {
            // dispatch(showLoader(false)) 
            message.error(error.message)
            return null
          }
    }

    const operacionAlCargarPagina = async () => {
        setLoading(true); // Mostrar el loading spinner
        const especialistaData = await getEspecialistaData(props.especialista)
        //console.log('continua con segunda instruccion')
        //console.log(especialistaData)

        // Generar lista de fechas desde las hr:mn hasta las hr:mn
        console.log("TABLA - MES DIA FECHA")
        const fechaActual = new Date()
        console.log(fechaActual)

        fechaActual.setFullYear(props.year)
        fechaActual.setDate(props.dia)
        fechaActual.setMonth(props.mes)
        
        console.log(props.mes)
        console.log(props.dia)
        console.log(fechaActual)

        setFechaActual(fechaActual)
        const listaFechasGeneradas = generarListaFechas(fechaActual, 
            especialistaData.hora_entrada, especialistaData.minuto_entrada, 
            especialistaData.hora_salida, especialistaData.minuto_salida, especialistaData.duracion_cita)
        // Convertir la lista de fechas en una lista de objetos que contengan la fecha
        const listaObjetosFecha = listaFechasGeneradas.map((fecha) => ({ fecha }))
        //console.log('se genero la lista de fechas')
        //console.log(listaObjetosFecha)

        const listaObjetosCita = await getCitasData(props.especialista, props.year, props.mes+1, props.dia)
        //console.log(listaObjetosCita)

        let listaUnida = listaObjetosFecha.map((itemLista1, index) => {
            const objetoEncontrado = listaObjetosCita.find((itemLista2) => compararHoraMinuto(itemLista1.fecha, itemLista2.fecha))

            const hora= itemLista1.fecha.toLocaleTimeString("en-US", { hour: '2-digit', minute: '2-digit', hour12: true })
                                       
            return {
                ...itemLista1,
                ...objetoEncontrado,
                hora,
                key:index
            }
        })

        // ahora procedemos a meter las citas customs
        const listaFiltrada = listaObjetosCita.filter((objeto) => objeto.custom === true);
        // Filtrar objetos que no se encontraron en objetoEncontrado
        // //console.log('aaaaaaaaa')
        // //console.log(//console.log(listaUnida))
        // const listaFiltrada = listaUnida.filter((item) => item.objetoEncontrado !== undefined);

        //console.log('los custoooooooms')
        //console.log(listaFiltrada);
        let listaActualizada = []
        listaFiltrada.forEach((nuevoObjeto) => {
          // buscar la posicion o index donde deberia de ir la nueva cita
          let insertIndex = listaUnida.findIndex(
            (objeto) => objeto.fecha > nuevoObjeto.fecha
          );
          
          if (insertIndex === -1) {
            insertIndex = listaUnida.length;
          }

          // genera  nueva lista con las citas actulizadas y formadas
          listaActualizada = [
            ...listaUnida.slice(0, insertIndex),
            { ...nuevoObjeto, key: insertIndex, hora:  nuevoObjeto.fecha.toLocaleTimeString("en-US", { hour: '2-digit', minute: '2-digit', hour12: true })  },
            ...listaUnida.slice(insertIndex).map((objeto, index) => ({
              ...objeto,
              key: insertIndex + 1 + index,
            })),
          ];

          // Ahora listaUnida tiene la misma información que listaActualizada
          listaUnida = listaActualizada;
        })


        //console.log('esta es la bueeenaaaa')
        //console.log(listaActualizada);


        setCitas(listaUnida)
        setData(listaUnida)
        //console.log(listaUnida)
        //console.log('aqui termina operacionAlCargarPagina')
        setLoading(false); // quitar loading spinner
      }
    

    useEffect(()=>{
        //console.log('se carga la pagina')
        
        operacionAlCargarPagina()
        //console.log('ya termino todo')
      }, [props.diaSelecionado, reloadCount])


      const handleReloadClick = () => {
        setReloadCount(reloadCount + 1); // Incrementa el contador para recargar el componente
      };

// se agregan mas propiedades a las columnas
      const mergedColumns = columns.map((col) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: (record) => ({
            record,
            inputType: col.dataIndex === 'pacienteCelular' ? 'number' : 'text',
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record),
          }),
        };
      })





    return(       
        <Form form={form} component={false}>
          <div
        style={{
          marginBottom: 16,
        }}
      >
         {loading && (
            <div className="loading-overlay">
            <Spin size="large" />
            </div>
        )}
        {/* <p style={{ fontSize: '16px', fontWeight: 'bold' }}>{props.nombre_completo}</p> */}
      <Row gutter={[16, 16]}>
        <Col>
        <span translate="no"><p style={{ fontSize: '18px', fontWeight: 'bold', display: 'inline-block' }}>{props.nombre_completo}</p></span>
        <span translate="no"><p style={{ fontSize: '16px', fontWeight: 'bold', display: 'inline-block', marginLeft: '36px' }}>{fechaActual.toLocaleDateString('es', {
              weekday: 'long',
              day: 'numeric',
              month: 'long',
              year: 'numeric',
            })}
          </p></span>
        </Col>
      </Row>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Space size={16}>
        <Popconfirm
          // title="Delete the task"
          description="Seguro que quieres Bloquear el dia?"
          okText="Si"
          cancelText="No"
          onConfirm={() => bloquearDia()}
        >
          <Button danger>Bloquear día</Button>
        </Popconfirm>
        
        <Button onClick={showModal}>Cita hora específica</Button>

      </Space>
      
      <Button icon={<ReloadOutlined />} onClick={handleReloadClick}>
          Recargar
        </Button>

      </div>
      <Modal
        title={"Agendar cita en hora especifica"}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p style={{ fontSize: '16px', fontWeight: 'bold', display: 'inline-block' }}>{props.nombre_completo}</p>
          <p style={{ fontSize: '16px', fontWeight: 'bold', display: 'inline-block', marginLeft: '36px' }}>{fechaActual.toLocaleDateString('es', {
              weekday: 'long',
              day: 'numeric',
              month: 'long',
              year: 'numeric',
            })}
          </p>
    
        <Form onFinish={onFinish} ref={formRef}>
          <Form.Item
          initialValue={null}
            label="Hora"
            name="fecha"
            rules={[{ required: true, message: "Por favor ingresa una hora" }]}
          >
            {/* <div translate="no" lang="es" > */}
            <TimePicker use12Hours format="hh:mm A" 
              disabledMinutes={disabledMinutes}
              showNow={false}
              locale={locale_esp}
              // getPopupContainer={(node) => node.parentNode}
              // cellRender={(current, { subType }) => {
              //   if (subType === 'meridiem') {
              //     return (
              //       <div style={{ color: 'red' }}>
              //         {current === 0 ? 'AM' : 'PM'}
              //       </div>
              //     );
              //   }
              //   return current
              // }
              // } 
            />
            {/* </div> */}
          </Form.Item>
          <Form.Item
          initialValue={null}
            label="Nombre"
            name="nombre"
            rules={[{ required: true, message: "Ingresa el nombre" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
          initialValue={null}
            label="Apellido"
            name="apellido"
            rules={[{ required: true, message: "Ingresa el apellido" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
          initialValue={null}
            label="Celular"
            name="pacienteCelular"
            rules={[
              { required: true, message: "Ingresa el teléfono" },
              {
                pattern: /^[0-9]{10}$/,
                message: "El teléfono debe tener 10 dígitos numéricos",
              },
            ]}
          >
            <Input type="number" maxLength={10} />
          </Form.Item>
        </Form>
      </Modal>

      </div>
            <Table
            components={{
                body: {
                cell: EditableCell,
                },
            }}
                key={"tablaCitas"}
                sticky={true}
                size="small"
                columns={mergedColumns}
                dataSource={citas}
                scroll={{
                // scrollToFirstRowOnChange:true,
                // x: "max-content",
                // y: "max-content",
                x: 1000,
                y: 430,
                // x:"10%",
                // y:"10%"
                }}
            />
        </Form>

    )
}

export default AdminTablaCita;