import {
  Button,
  Card,
  Form,
  Input,
  Radio,
  Select,
  Space,
  TimePicker,
  message,
  Spin,
  Modal
} from "antd";
import { Option } from "antd/es/mentions";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Tabs } from 'antd';
import {
  adminGetEspecialidades,
  adminGetEspecialistas,
  adminGetEspecialistaData
} from "../../api_calls/especialidades"
import { doc } from "firebase/firestore";
// import moment from 'moment';
// import 'moment/locale/es'; 
import AdminTablaCita from '../../components/adminTablaCitas';



const fechaActual1 = new Date();
const fechaActual2 = new Date();
fechaActual2.setFullYear(fechaActual1.getFullYear() + 1)
const yearsWindow = [fechaActual1, fechaActual2]

const mesesLista1 = Array.from({ length: 12 }, (_, index) => new Date(yearsWindow[0].getFullYear(), index, 1))
const mesesLista2 = Array.from({ length: 12 }, (_, index) => new Date(yearsWindow[1].getFullYear(), index, 1))
const mesesWindow = [mesesLista1, mesesLista2]

const diasPorMesList1 = mesesLista1.map((firstDayOfMonth) => {
  const daysInMonth = new Date(firstDayOfMonth.getFullYear(), firstDayOfMonth.getMonth() + 1, 0).getDate();
  return Array.from({ length: daysInMonth }, (_, index) => new Date(yearsWindow[0].getFullYear(), firstDayOfMonth.getMonth(), index + 1));
})
const diasPorMesList2 = mesesLista2.map((firstDayOfMonth) => {
  const daysInMonth = new Date(firstDayOfMonth.getFullYear(), firstDayOfMonth.getMonth() + 1, 0).getDate();
  return Array.from({ length: daysInMonth }, (_, index) => new Date(yearsWindow[1].getFullYear(), firstDayOfMonth.getMonth(), index + 1));
})
const diasPorMesWindow = [diasPorMesList1, diasPorMesList2]

// Crear un objeto Intl.DateTimeFormat para obtener el nombre del día en español
const diaNombreFormatter = new Intl.DateTimeFormat('es', { weekday: 'short' });



function Citas() {

const [dataEspecialidades, setDataEspecialidades] = useState([]);
const [dataEspecialista, setDataEspecialista] = useState([]);
const [selectedDia, setDia] = useState(null);
const [loading, setLoading] = useState(true);

// const response = await adminGetEspecialidades()
const getEspecialiadadesData = async () => {
  try{ 
    const responseEspecialidades = await adminGetEspecialidades()
    if (responseEspecialidades.success === true) {
      setDataEspecialidades(responseEspecialidades.data)

      const responseEspecialistas = await adminGetEspecialistas()
      // console.log(responseEspecialistas)
      // console.log(responseEspecialidades.data[0].id)
      if (responseEspecialistas.success === true) {
        //Crear lista de especialistas del primer especialidad 
        const listaEspecialistasCero = responseEspecialistas.data.filter((option) => option.especialidad === responseEspecialidades.data[0].id)

        console.log(listaEspecialistasCero)
        setDataEspecialista(listaEspecialistasCero)
      }else{
        throw new Error(responseEspecialistas.message)
      }
    }else{
      throw new Error(responseEspecialidades.message)
    }
  }catch(error){
    message.error(error.message)
  }finally{
    setLoading(false)
  }
  
  
}


const getEspecialistasData = async (especilidad) => {
  const responseEspecialistas = await adminGetEspecialistas()

  if (responseEspecialistas.success === true) {
    //Crear lista de especialistas del primer especialidad 
    const listaEspecialistas = responseEspecialistas.data.filter((option) => option.especialidad === especilidad)

    // console.log(listaEspecialistas)
    setDataEspecialista(listaEspecialistas)
  }else{
    throw new Error(responseEspecialistas.message)
  }
}


const getEspecialistaData = async (especialista) => {
  const responseEspecialista = await adminGetEspecialistaData()

  if (responseEspecialista.success === true) {
    //Crear lista de especialistas del primer especialidad 
    const listaEspecialistas = responseEspecialista.data.filter((option) => option.especialidad === especialista)

    // console.log(listaEspecialistas)
    setDataEspecialista(listaEspecialistas)
  }else{
    throw new Error(responseEspecialista.message)
  }
}


const onChangeEspecialidad = (key) => {
  // console.log(key);
  getEspecialistasData(key)
}

const onChangeEspecialista = (key) => {
  // console.log(key);
  // getEspecialistasData(key)
}

const onChangeDia= (key) => {
  // console.log(key);
  // getEspecialistasData(key)
  setDia(key)
}




useEffect(()=>{
  getEspecialiadadesData()
}, [])

  if(loading){
    return 
      <div className="loading-overlay">
        <Spin size="large" />
      </div>
    
  }

  return (
    <Tabs
      onChange={onChangeEspecialidad}
      type="card"
      // style={{height: "100vh"}}
      items={dataEspecialidades.map((documento, i) => {
        return {
          label:  <span translate="no">{documento.especialidad}</span>,
          key: documento.id,
          children: <Tabs
            tabPosition="left"
            onChange={onChangeEspecialista}
            items={dataEspecialista.map((doc, id)=>{
              return {
                label:  <span translate="no">{doc.nombre}</span>,
                key: doc.id,
                children: 
                  <Tabs
                    // defaultActiveKey="1"
                    // tabPosition="left"
                    // style={{ height: 220 }}
                    // style={{height: "70vh"}}
                    style={{padding:0}}
                    size="small"
                    items={yearsWindow.map((yearDate, yearIndex) => {
                      return {
                        label: yearDate.getFullYear(),
                        key: yearDate.getFullYear(),
                        // disabled: i === 28,
                        children: <Tabs
                        style={{padding:0}}
                          size="small"
                          items={mesesWindow[yearIndex].map((mesYearDate, mesIndex) => {
                            
                            return {
                              label:  <span translate="no">{mesYearDate.toLocaleString('es', { month: 'short' }).replace(/^\w/, (c) => c.toUpperCase())}</span>,
                              key: 'm'+mesYearDate.getMonth(),
                              // disabled: i === 28,
                              children: <Tabs
                                // tabPosition={"vertical"}
                                
                                onChange={onChangeDia}
                                tabPosition="left"
                                size="small"
                                tabBarGutter={0}
                                style={{
                                  height: 540,
                                  marginTop: 0,
                                  padding:0
                                }}
                                items={diasPorMesWindow[yearIndex][mesIndex].map((diaMesDate, diaIndex) => {
                                  // setDia(diaIndex)
                                  return {
                                    disabled: diaMesDate.getDay() === 0 || diaMesDate.getDay() === 6,
                                    label:   <span translate="no">{diaNombreFormatter.format(diaMesDate).slice(0, 3).replace(/^\w/, (c) => c.toUpperCase()) + " " + diaMesDate.getDate()}</span>,
                                    key: 'd'+diaMesDate.getDate(),
                                    // disabled: i === 28,
                                    children: <AdminTablaCita 
                                      servicio={documento.id ? documento.id : "servicio nulo"} 
                                      especialista={doc.id} 
                                      year={yearDate.getFullYear()}
                                      mes={mesYearDate.getMonth()}
                                      dia={diaMesDate.getDate()}
                                      diaSelecionado={selectedDia}
                                      nombre_completo = {doc.nombre+" "+doc.ape_paterno+" "+doc.ape_materno}
                                      
                                    />,
                                  };
                                })}
                              />
                            }
                          })}
                        />
                      }
                    })}
                  />
              }
            })}
          />
          

        }
      })}
    />
  )
}

export default Citas


